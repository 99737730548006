import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import { suid } from 'rand-token';
import { toast } from 'react-toastify'
// import beta_dda_logo from './image/adda6.png'
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Combine from './Combine';
import DasboardDemo from './DasboardDemo'


const cookies = new Cookies();
window.flag = 0;
var cc;
var tt;

const useStyles = makeStyles({
    root: {
        border: 0,
        borderRadius: 4,
        height: 45,
        padding: '0 15px',
    },
});



export default function LoginApp(props) {
    const [Socialicon, setSocialicon] = useState(0);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [userid, setId] = useState("");
    const [password, setPassword] = useState("");
    const [captchaSuccess, setCaptcha] = useState(false);
    const [loading, setloading] = useState(false);
    const [firstlogin, setfirstlogin] = useState(0);




    function createCaptcha() {
        if (document.getElementById('captch')) {
            document.getElementById("captch").remove();
        }

        var captcha = [];
        while (captcha.length < 4) {
            //below code will not allow Repetition of Characters
            var index = Math.floor(Math.random() * 10); //get the next character from the array

            captcha.push(index);

        }
        var canv = document.createElement("canvas");
        canv.style.width = '79px';
        canv.style.marginLeft = '20px';
        canv.style.height = '39px';
        canv.style.color = '#fff';
        canv.style.marginBottom = '12px';
        canv.style.backgroundColor = '#fff';
        canv.id = "captch";
        canv.width = 70;
        canv.height = 50;


        var ctx = canv.getContext("2d");
        ctx.font = "600 30px Arial";
        ctx.fillText(captcha.join(""), 0, 30)
        //ctx.strokeText(captcha.join(""), 0, 30);
        //storing captcha so that can validate you can save it somewhere else according to your specific requirements
        cc = captcha.join("");

        if (document.getElementById("popupcaptcha")) {
            document.getElementById("popupcaptcha").appendChild(canv);
        }
        else {
            document.getElementById("captcha").appendChild(canv);
        }



    }


    function validateCaptcha(event) {
        //event.preventDefault();

        if (event.target.value === cc) {
            setCaptcha(true);

        } else {
            setCaptcha(false);
        }

    }

    useEffect(() => {

        createCaptcha();

    }, []);



    const handleLogin = () => {
        if (userid === '') {

            createCaptcha();
            //toast.warn('Username can not be blank!', {position:toast.POSITION.TOP_CENTER})
            document.getElementById("errorMsg").innerHTML = "Username is empty";


            return;
        }
        if (password === '') {
            createCaptcha();
            //toast.warn('Password can not be blank!', {position:toast.POSITION.TOP_CENTER})
            document.getElementById("errorMsg").innerHTML = "Password is empty";
            return;
        }
        if (!captchaSuccess) {

            createCaptcha();
            // toast.warn('Captcha is not valid!', {position:toast.POSITION.TOP_CENTER})
            document.getElementById("errorMsg").innerHTML = "Captcha is not valid!";
            return;
        }
        var demoid1 = 'demo01';
        //var demoid2 = 'demo02'
        var token = suid(16);
        if (userid.toUpperCase() === demoid1.toUpperCase()) {
            token = '123456789';
        }
        tt = token;

        cookies.set('id', userid, { path: '/' });
        cookies.set('token', token, { path: '/' });


        axios.post('https://waveapi.in/api/client/clientlogin', {
            id: userid,
            password: password,
            token: token
        })
            .then(result => {

                if (result.status === 201) {

                    setfirstlogin(1);

                }
                else if (result.status === 202) {

                    setfirstlogin(2);

                }

                else if (result.status === 200) {

                    setLoggedIn(true);
                    //props.checkLogin(true);
                    setCaptcha(false);
                    window.location.href = '/DasboardDemo';
                    //window.location.reload();


                }
                else if (result.status === 205) {
                    setCaptcha(false);
                    //toast.warn('User Blocked!', {position:toast.POSITION.TOP_CENTER})
                    document.getElementById("errorMsg").innerHTML = "User Blocked!";
                    createCaptcha();
                }
                else {
                    setCaptcha(false);
                    //toast.warn('Username or password incorrect!', {position:toast.POSITION.TOP_CENTER})
                    document.getElementById("errorMsg").innerHTML = "Username or password incorrect!";
                    createCaptcha();
                }
            }

            ).catch(e => {
                setIsError(true);
            });
    };


    const classes = useStyles();

    return (
        <>
            <body style={{ backgroundColor: '#fff' }}>
                <div class="login_app_strech"  >

                    <div class="login_division_section" style={{ backgroundColor: "rgb(255 255 255)" }}>
                        <div style={{ float: "center", }}>
                            <div style={{ width: '100%', }}>
                                <div class="mobileLoging" style={{ height: 'auto', width: '143%', backgroundColor: '#983838' }}>
                                    <div style={{ height: '50px', width: '100%', float: 'left', color: 'white', fontSize: '20px', fontFamily: 'sans-serif', textAlign: 'center', }} >
                                        <h1 class="login_heading">LOG IN</h1>
                                    </div>
                                    <div class="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
                                        <input type="Text" placeholder="User Name" class="form-control" id="Client_id" value={userid} onChange={(e) => { setId(e.target.value) }} aria-describedby="emailHelp"
                                            style={{ width: '100%', height: '35px', border: '1px solid rgba(var(--b6a,219,219,219),1)', fontSize: '14px', backgroundColor: "white",fontWeight: '400'  ,  letterSpacing: '1.5px' }} />
                                    </div>
                                    <div class="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
                                        <input type="password" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) }} class="form-control" id="exampleInputPassword1" style={{fontWeight: '400' , fontSize: '14px',letterSpacing: '1.5px'}}
                                        />
                                    </div>
                                    <div class="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
                                        <div id="popupcaptcha" className="CaptchaPopup xyz"> </div>
                                        <input id="inputType" name="username" onChange={(e) => { validateCaptcha(e) }} autocomplete="off" className={classes.root} type="text" placeholder="Enter Number  " style={{ width: '100%', flexDirection: 'row-reverse', height: '35px', fontSize: '12px' }} />
                                    </div>
                                    <div class="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
                                      
                                        <div id="errorMsg" style={{color: '#fff'}}></div>
                                        <input class="btn btn-danger purp_btn" style={{ position: 'relative', left: '12.4rem' }} name="commit" type="submit" value="LOG IN" onClick={handleLogin} />
                                    </div>



                                </div>


                            </div>

                        </div>
                    </div>


                </div>
            </body>
        </>
    )
}


// <body className="color-user-login-body">
// <div className="container set-login-page-bg-color">
//     <div className="row login-sub-container">
//         <div className="col-lg-4 col-md-4 col-sm-6 col-xs-10 col-centered login_stripe login-box-color">
//             <h1 className="login_heading">LOG IN</h1>
//             <form acceptCharset="UTF-8" className="new_user" method="post" id="fromTarget" action="/home">

//                 <h4 id="errortext" style={{ color: '#FB3A3A !important', fontSize: '12px !important', fontWeight: '600 !important', letterSpacing: '1px' }} />
//                 <div className="row">
//                     <div className="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
//                         <input className="form-control login_text_field" id="user_user_name" name="userid" placeholder="User Name" onChange={(e) => { setId(e.target.value) }} required="required" type="text" defaultValue />
//                         <br />
//                     </div>
//                 </div>
//                 <div className="row">
//                     <div className="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
//                         <input className="form-control login_text_field" id="user_password" name="password" placeholder="Password" onChange={(e) => { setPassword(e.target.value) }} required="required" type="password" />
//                         <br />
//                     </div>
//                 </div>
//                 <div className="row">
//                     <div className="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
//                         <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n  /*.simple_captcha{border: 1px solid #ccc; padding: 5px !important;}*/\n  .simple_captcha,\n  /*.simple_captcha div{display: table;}*/\n  .simple_captcha .simple_captcha_field,\n  .simple_captcha .simple_captcha_image{\n    /*border: 1px solid #ccc;*/\n    margin: 0px 0px 10px 0px !important;\n    padding: 0px !important;\n  }\n  .simple_captcha .simple_captcha_image img{\n    margin: 0px !important;\n    padding: 0px !important;\n   /* width: 110px !important;*/\n  }\n  .simple_captcha .simple_captcha_label{font-size: 12px;}\n  .simple_captcha .simple_captcha_field input{\n    /*width: 150px !important;*/\n/*    font-size: 16px;\n    border: none;\n    background-color: #efefef;*/\n    font-size: 14px !important;\n   // font-family: inherit; \n    letter-spacing: 1.5px;\n    border: none !important;\n    border-radius: 8px !important;\n    color: #848484 !important;\n    height: 40px; \n    width: 100%;\n    padding: 6px 12px;\n  }\n  /* Apply the translate and rotate transformation \n           for our text to look curved  */ \n  \n        .G1 { \n            transform:translate(4px,3px) rotate(-3deg);\n            \n        } \n  \n        .e1 { \n            transform:translate(-2px,3px) rotate(4deg); \n        } \n  \n        .e2 { \n            transform:translate(-7px,5px) rotate(8deg);  \n        }  \n  \n        .k1 { \n transform:translate(-12px,7px) rotate(9deg); \n        } \n  \n        /* An inline-block element is placed as an inline \n element (on the same line as adjacent content), \n but it behaves like a block element  */ \n  \n span { \n display: inline-block; \n }\n" }} />
//                         <div className="simple_captcha">

//                             <p style={{ fontFamily: '"Raleway", sans-serif', letterSpacing: '0.8px', background: '#fff', color: '#58825a', fontSize: '20px', textAlign: 'center', fontWeight: 600, width: '120px', height: '44px', margin: '0 auto' }}>

//                                 <span className="G1" id="num1"> <div id="popupcaptcha" className="CaptchaPopup"> </div></span>
//                                 <span className="e1" id="num2">3</span>
//                                 <span className="e2" id="num3">0</span>
//                                 <span className="k1" id="num4">1</span>
//                             </p>
//                             <br />
//                             <div className="simple_captcha_field">

//                                 <input id="captcha" placeholder="Enter Number" className={classes.root} onChange={(e) => { validateCaptcha(e) }} required="required" type="text" name="createdon" />
//                                 <input type="hidden" />
//                             </div>
//                         </div>
//                         <br />
//                     </div>
//                 </div>
//                 <input className="btn btn-danger purp_btn" name="commit" type="submit" onClick={handleLogin} defaultValue="LOG IN" />
//             </form>
//         </div>
//     </div>
// </div>
// </body>




{/* <div className="row">
<div className="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
    <div className="simple_captcha">
        <p style={{ fontFamily: '"Raleway", sans-serif', letterSpacing: '0.8px', background: '#fff', color: '#58825a', fontSize: '20px', textAlign: 'center', fontWeight: 600, width: '120px', height: '44px', margin: '0 auto' }}>
            <span className="G1" id="num1">1</span>
            <span className="e1" id="num2">8</span>
            <span className="e2" id="num3">0</span>
            <span className="k1" id="num4">7</span>
        </p>
        <br />
        <div className="simple_captcha_field">
            <input name="username" className="form-control" type="number" style={{ display: 'none' }} id="validate2" />
            <input id="captcha" placeholder="Enter Number" required="required" type="text" name="createdon" />
            <input type="hidden" />
        </div>
    </div>
    <br />
</div>
</div> */}


