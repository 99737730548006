import React, { useState, useEffect } from 'react'
import Axios from 'axios';
import { array } from 'yup';

export default function Mybets(props) {
    const [matchOption, setmatchOption] = useState(1)
    const [view, setview] = useState(1)
    const [bets, setbets] = useState([])
    const [betVal1, setbetVal1] = useState([])
    const [betVal2, setbetVal2] = useState([])
    const [betVal3, setbetVal3] = useState([])
    const [betVal3F, setbetVal3F] = useState([])
    const [runner1, setrunner1] = useState()
    const [runner2, setrunner2] = useState()
    const [runner3, setrunner3] = useState()


    const [menuName, setmenuName] = useState()

    var marketName1 = "Match Odds";
    var marketName2 = "Bookmaker";
    var marketName3 = "fancy";
    useEffect(() => {
        Axios.post('https://waveapi.in/api/client/event_nameclient', {
            marketId:props.marketid,
            eventId: props.eventId,

        }).then(result => {
            setrunner1(result.data[0].runnerName1)  
            setrunner2(result.data[0].runnerName2)                   
    

            }).catch(e => {console.log(e);});
    }, []);
    useEffect(() => {
        Axios.post('https://waveapi.in/api/client/clientgetbetss', {
            id: props.userid,
            token: props.utoken,
            eventId: props.eventId,
            // marketName: marketName3,

        }).then(result => {
             
                var M_bet = [],fancyBet = [];
                for (var i = 0; i < result.data.length; i++)
                    if (result.data[i].betType == 'fancy')      
                        fancyBet.push(result.data[i]);
                    else  
                        M_bet.push(result.data[i]);
                setbetVal1(M_bet);
                setbetVal2(fancyBet);         

            }

            ).catch(e => {
                console.log(e);
            });
    }, [props.userid, props.updateBet]);

    //     useEffect(() => {
    //       if(!props.userid) return;  

    //       Axios.post('https://waveapi.in/api/client/clientgetbets',{
    //       id:props.userid,
    //       token:props.utoken,
    //       eventId:props.eventId,
    //       marketName:marketName1,


    //     })
    //      .then(result => {

    //         console.log('bets',result.data);
    //        if(result.data){

    //         console.log(result.data)



    //         setbetVal1(result.data);


    //          }

    //      }
    //      ).catch(e => {
    //       console.log(e);
    //   });
    //  },[props.userid,props.updateBet]);



    //  useEffect(() => {
    //     if(!props.userid) return;  

    //     Axios.post('https://waveapi.in/api/client/clientgetbets',{
    //     id:props.userid,
    //     token:props.utoken,
    //     eventId:props.eventId,
    //     marketName:marketName2,


    //   })
    //    .then(result => {

    //       console.log('bets',result.data);
    //      if(result.data){

    //       console.log(result.data)



    //       setbetVal1(result.data);


    //        }

    //    }
    //    ).catch(e => {
    //     console.log(e);
    // });
    // },[props.userid,props.updateBet]);



    //  useEffect(() => { 
    //       Axios.post('https://waveapi.in/api/client/clientgetbets',{
    //       id:props.userid,
    //       token:props.utoken,
    //       eventId:props.eventId,
    //       marketName:marketName3,

    //     })
    //      .then(result => {
    //         console.log('bets',result.data);
    //        if(result.data){
    //         setbetVal2(result.data); 

    //        }

    //      }

    //      ).catch(e => {
    //          console.log(e);
    //      });
    //     },[props.userid,props.updateBet]);







    return (
        <React.Fragment>
            <div class="container matchList-matchBox-outer matchBox-outer" style={{ padding: 0, overflow: 'inherit' }}>

                <React.Fragment>
                    <div class="" style={{ paddingTop: "15px" }}>
                        <table class="table table-bordered">
                            {betVal1.length > 0 &&
                                <thead>
                                    <tr>
                                        <th colspan="5" class="bet-place-tbl-th market_type_row market-name-row-color" style={{width:'130px'}}>MATCH BETS</th>
                                    </tr>
                                    <tr>
                                        <th class="bet-place-tbl-th run-pos-rate-amt-run-mod-color">MARKET</th>
                                        <th class="bet-place-tbl-th run-pos-rate-amt-run-mod-color">RUNNER</th>
                                        <th class="bet-place-tbl-th mob-col-width-ch run-pos-rate-amt-run-mod-color">TYPE</th>
                                        <th class="bet-place-tbl-th run-pos-rate-amt-run-mod-color">RATE</th>
                                        <th class="bet-place-tbl-th run-pos-rate-amt-run-mod-color">AMOUNT</th>
                                    </tr>
                                </thead>}
                            <tbody>

                                {betVal1.map(function (item, index) {
                                    var rate;
                                    if (item.marketName == 'Bookmaker') {
                                        rate = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
                                    }
                                    else {
                                        rate = parseFloat(parseFloat(item.rate) * 100).toFixed(2);
                                    }
                                    return (
                                        <tr key={index}>
                                            <td class="bet-place-tbl-td runs-2-value-box-color" style={{ textAlign: "left" }}>
                                                {item.betType}</td>
                                            <td class="bet-place-tbl-td runs-2-value-box-color" style={{ textAlign: "left" }}>
                                                {item.teamName === 'A' ? runner1 : item.teamName === 'B' ? runner2 : 'The Draw'}</td>
                                            <td class="bet-place-tbl-td mob-col-width-ch runs-2-value-box-color">
                                                {item.type}
                                            </td>
                                            <td data-modal="modal-1" class="bet-place-tbl-td md-trigger runs-2-value-box-color">{rate}</td>
                                            <td class="bet-place-tbl-td runs-2-value-box-color">{item.amount}</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            


        

                <React.Fragment>
                    <div class="">
                        <table class="table table-bordered">
                            {betVal2.length > 0 &&
                                <thead>
                                    <tr>
                                        <th colspan="4" class="bet-place-tbl-th market_type_row market-name-row-color">SESSION BETS</th>
                                    </tr>
                                    <tr>
                                        <th class="bet-place-tbl-th run-pos-rate-amt-run-mod-color">RUNNER NAME</th>
                                        <th class="bet-place-tbl-th mob-col-width-ch run-pos-rate-amt-run-mod-color">TYPE</th>
                                        <th class="bet-place-tbl-th run-pos-rate-amt-run-mod-color">RATE</th>
                                        <th class="bet-place-tbl-th run-pos-rate-amt-run-mod-color">AMOUNT</th>
                                    </tr>
                                </thead>}

                            <tbody>
                                {betVal2.map(function (item, index) {


                                    return (
                                        <tr id={index}>
                                            <td class="bet-place-tbl-td runs-2-value-box-color" style={{ textAlign: "left" }}>
                                                {item.runnerName}</td>
                                            <td class="bet-place-tbl-td mob-col-width-ch runs-2-value-box-color">
                                                {item.type}
                                            </td>
                                            <td data-modal="modal-1" class="bet-place-tbl-td md-trigger runs-2-value-box-color">{item.rate}</td>
                                            <td class="bet-place-tbl-td runs-2-value-box-color">{item.amount}</td>
                                        </tr>

                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                </React.Fragment>

            </div>
{/* 
            <div>
                {betVal2.length > 0 &&
                    <div>
                        <div class="statment_mainline">Bets</div>
                        <div class="desktop_mobile_width" >
                            <div style={{ height: 'auto', width: '100%', overflowY: 'scroll' }}>
                                <div class="statement_containtes"  >
                                    <li className="desth_border" style={{ width: '20%' }}>Date</li>
                                    <li className="desth_border" style={{ width: '25%' }}>Description</li>
                                    <li className="desth_border" style={{ width: '15%' }}>Credit</li>
                                    <li className="desth_border" style={{ width: '10%' }}>Debit</li>
                                    <li className="desth_border" style={{ width: '10%' }}>Com+</li>
                                    <li className="desth_border" style={{ width: '10%' }}>Com-</li>
                                    <li className="desth_border" style={{ width: '10%' }}>Balance</li>
                                </div>

                                {betVal2.map(function (item, index) {

                                    var obj1;
                                    var obj2;
                                    if (item.coin >= 0) {
                                        obj1 = parseFloat(item.coin).toFixed(2);
                                        obj2 = '-';
                                    }
                                    if (item.coin < 0) {
                                        obj1 = '-';
                                        obj2 = parseFloat(item.coin).toFixed(2);
                                    }
                                    var obj3 = parseFloat(item.fixLimit).toFixed(2);

                                    return (
                                        <div class="statement_data statement_containtes" key={index}>
                                            <li className="desth_border" style={{ width: '20%' }}>{item.date}</li>
                                            <li className="desth_border" style={{ width: '25%' }}>{item.eventName}</li>
                                            <li className="desth_border" style={{ width: '15%' }}>{item.Debit}</li>
                                            <li className="desth_border" style={{ width: '10%' }}>{item.Credit}</li>
                                            <li className="desth_border" style={{ width: '10%' }}>--</li>
                                            <li className="desth_border" style={{ width: '10%' }}>--</li>
                                            <li className="desth_border" style={{ width: '10%' }}>{item.balance}</li>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
            </div> */}




        </React.Fragment>
    )
}
