import axios from 'axios';
import React, { useEffect, useState } from 'react'
export default function CricketScore(props) {
  const [scoreId, setscoreId] = useState(null);
  const [Inn1, setInn1] = useState(null);
  const [thisOver, setthisOver] = useState([]);
  const [runner1, setrunner1] = useState('');
  const [runner2, setrunner2] = useState('');

  var Overs = props.ScoreData.teamAOver || props.ScoreData.teamBOver;


  var over = (Math.round(props.ScoreData.teamBOver) + (props.ScoreData.teamBOver - Math.round(props.ScoreData.teamBOver)) * (5 / 3)).toFixed(2);
  var over1 = (Math.round(props.ScoreData.teamAOver) + (props.ScoreData.teamAOver - Math.round(props.ScoreData.teamAOver)) * (5 / 3)).toFixed(2);
  var CRRA = (props.ScoreData.teamAScore / over1).toFixed(2);
  var CRRB = (props.ScoreData.teamBScore / over).toFixed(2);
  var totalOver = over - over1;
  var totalOver1 = over1 - over;
  var Curent_rate = CRRA || CRRB;
  var CRR1 = props.ScoreData.innings;
  var CurRR = props.ScoreData.current_run_rate;


  if (props.ScoreData.teamAName == null || props.ScoreData.teamAName === ' ') {
    props.ScoreData.teamAName = props.marketData.runnerName1;
  }
  if (props.ScoreData.teamBName == null || props.ScoreData.teamBName === ' ') {
    props.ScoreData.teamBName = props.marketData.runnerName2;
  }






  function acronym(text) {
    return text
      .split(/\s/)
      .reduce(function (accumulator, word) {
        return accumulator + word.charAt(0);
      }, '');
  }




  useEffect(() => {
    if (!props.eventId) { return; }
    axios.post("https://waveapi.in/api/client/clientlivestreaming", {
      eventid: props.eventId,
    })
      .then((result) => {
        if (result.status === 210) {
     
          if (result.data[0].scoreId && result.data[0].scoreId != '') {
            setscoreId(result.data[0].scoreId);


          }
          if (result.data[0].inn1 && result.data[0].inn1 != '') {
            setInn1(result.data[0].inn1);

          }
        }
      })
      .catch((e) => {
      });

    axios.post("https://waveapi.in/api/client/clientgetRunner", {
      eventid: props.eventId,
    })
      .then((result) => {
        if (result.status === 210) {
          if (result.data[0].runnerName1.split(' ').length > 1) {
            setrunner1(acronym(result.data[0].runnerName1));
          }
          else {
            setrunner1(result.data[0].runnerName1);
          }
          if (result.data[0].runnerName2.split(' ').length > 1) {
            setrunner2(acronym(result.data[0].runnerName2));
          }
          else {
            setrunner2(result.data[0].runnerName2);
          }
        }
      })
      .catch((e) => {
      });
  }, [props.eventId, runner1, runner2]);


  useEffect(() => {
    if (!props.ScoreData || !props.ScoreData.thisOver || props.ScoreData.thisOver == '') {
      return;
    }
    let ov = props.ScoreData.thisOver.split('.');
    setthisOver(ov);

  }, [props.ScoreData]);







  return (
    <React.Fragment>
      {/* <React.Fragment>
  
          <div class="container matchList-matchBox-outer matchBox-outer" style={{ padding: '0px' }}>
            <div id="normalscore" className="row content-top-padding">
              <div id="cricketScore" style={{ width: '100%', background: 'rgb(152 55 55)' }}>
  
                <div _ngcontent-gyh-c22="" id="normalscore" className="content-top-padding" style={{ padding: 0, borderLeft: '5px solid #e9e9e9 !important', borderRight: '5px solid #e9e9e9 !important' }}>
                  <div _ngcontent-gyh-c22="">
                    <div _ngcontent-gyh-c22="" className="col-lg-8 col-xs-9 col-md-8 col-sm-7 match-score content_in_play_wrapper match-status-table match-score-box-color">
                      <p _ngcontent-gyh-c22="" className="inplay-show-tbl-td match-status-text match-score-box-color">
                      <span _ngcontent-gyh-c22="" id="scorename" className="spfont" style={{ display: 'inline-block', width: '500px',fontWeight: '100' }}> {props.ScoreData.teamAName} - {props.ScoreData.teamBScore}/{props.ScoreData.teamBWicket}({props.ScoreData.teamBOver} ) &amp; 50/2(20.0 </span>
                      <span _ngcontent-gyh-c22="" id="scorecsecing" className="spfont" style={{display:'inline-block', width: '500px',fontWeight: '100'}}>{props.ScoreData.teamBName} -<span> {props.ScoreData.teamAScore}/{props.ScoreData.teamAWicket}</span> {props.ScoreData.teamAScore == null && (props.ScoreData.teamAScore != null || props.ScoreData.teamBScore != null) && <span>Yet to bat</span>}(112.5)</span><span _ngcontent-gyh-c22="" id="scorec" className="spfont" style={{ display: 'block', margin: '0 auto', textAlign: 'center', width: '500px' }} /></p>
                    </div>
                    <div _ngcontent-gyh-c22="" className="col-lg-3 col-xs-3 col-md-3 col-sm-3 bet_status_box bet-status-text-div" style={{ textAlign: 'center', display: 'grid', placeItems: 'center' }}>
                    <span _ngcontent-gyh-c22="" id="statusball" className="bet-status-text-p event commentry-box-color" style={{fontWeight: 'bold', fontSize: '20px', display: '-webkit-box', height: 'auto !important', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis',fontWeight: '100' }}>
                    Stumps </span></div>
                    <div _ngcontent-gyh-c22="" id="refresh2" className="col-lg-1 col-xs-2 col-md-1 col-sm-2 refresh-btn reload-class refresh-btn-color"><i _ngcontent-gyh-c22="" aria-hidden="true" className="refresh_img fa fa-refresh" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
  
  
  
        </React.Fragment> */}
      {/* <div login_app_strech>
          <div className="score_main_div xyz ">
            <div className="score_main_div_left xyz" >
              <span id="scorecsecing" className="spfont" style={{ ontWeight: 'bold' }}>{props.ScoreData.teamAName} : &nbsp; {props.ScoreData.teamAScore}/{props.ScoreData.teamAWicket}({props.ScoreData.teamAOver==null? props.ScoreData.over:props.ScoreData.teamAOver} )</span>
            </div>
            <div className="score_main_div_right xyz" >
              <span id="scorename" className="spfont" style={{ fontWeight: 'bold' }}>{props.ScoreData.teamBName} : &nbsp;  {props.ScoreData.teamBScore}/{props.ScoreData.teamBWicket}({props.ScoreData.teamBOver==null? props.ScoreData.over:props.ScoreData.teamBOver} ) </span>
            </div> */}
      {/* {props.ScoreData.teamAScore == null && (props.ScoreData.teamAScore != null || props.ScoreData.teamBScore != null) && <span>Yet to bat</span>}(112.5) */}

      {/* <p _ngcontent-gyh-c22="" className="inplay-show-tbl-td match-status-text match-score-box-color">
                <span _ngcontent-gyh-c22="" id="scorename" className="spfont" style={{ display: 'inline-block', width: '500px', fontWeight: '100' }}> {props.ScoreData.teamAName} - {props.ScoreData.teamBScore}/{props.ScoreData.teamBWicket}({props.ScoreData.teamBOver} ) &amp; 50/2(20.0 </span>
                <span _ngcontent-gyh-c22="" id="scorecsecing" className="spfont" style={{ display: 'inline-block', width: '500px', fontWeight: '100' }}>{props.ScoreData.teamBName} -<span> {props.ScoreData.teamAScore}/{props.ScoreData.teamAWicket}</span> {props.ScoreData.teamAScore == null && (props.ScoreData.teamAScore != null || props.ScoreData.teamBScore != null) && <span>Yet to bat</span>}(112.5)</span>
              </p> */}
      {/*   
          </div>
        </div>
        */}
      {/* <iframe align="center" scrolling="no" style={{width: '100%', border: 'none', margin: '0', padding: '0', display: 'block'}}  src={`https://allscore.xyz/cricket-score/${props.eventId}`} > </iframe> */}
      {/* <div id="normalscore" className="row content-top-padding">
          <iframe id="cricketScore" src={`http://143.110.251.253/#/score2/${props.eventId}`} style={{ width: '100%', background: 'rgb(152 55 55)' }} title="Match Score" />
        </div> */}
      <iframe data-v-d49a2250="" id="sportradar_score" src={`https://clickbetexch.com/get-scorecard-iframe/3/${props.eventId}/${scoreId}`} marginwidth="0" marginheight="0" frameborder="0" width="100%" scrolling="no" allowfullscreen="allowfullscreen" className="sports-radar-scorecard-iframe" style={{ height: '190px' }}></iframe>

    </React.Fragment>
  )
}


