import React from 'react'

export default function BetplaceMsg(props) {
    return (
        <React.Fragment>
            {props.msgNumber === 1 &&
            <div id="footer-rg" class="overlay" >
                 <div class="js-bet-message-wrapper ">
                    <div class="biab_bet-matched" style={{position:' fixed', top: '300px', zIndex: '9999', display: 'block'}}>
                        <div class="biab_matching-bet-msg biab_visible-xs js-matching-bet-msg biab_type-bet biab_bet-status-matched biab_type-bet-back"><span class="biab_matched-label js-matched-label"></span>
                            <p class="biab_matched-details js-matched-details" style={{textAlign:"center"}}>Bet placed succefully!<br/>{props.betInfo.amount} at odds of {props.betInfo.rate}<br/></p>
                        </div>
                    </div>
                    </div>
                </div>
               } 
                {props.msgNumber === 2 &&
                 <div class="js-bet-message-wrapper">
                 <div class="biab_bet-matched" style={{position:' fixed', top: '300px', zIndex: '9999', display: 'block'}}>
                     <div class="biab_matching-bet-msg biab_visible-xs js-matching-bet-msg biab_type-bet biab_bet-status-matched biab_type-bet-back" style={{backgroundColor:'red'}}><span class="biab_matched-label js-matched-label"></span>
                         <p class="biab_matched-details js-matched-details">Cannot place bet. The stake you have entered are below the minimum.</p>
                     </div>
                 </div>
             </div>}
             {props.msgNumber === 3 &&
                 <div class="js-bet-message-wrapper">
                 <div class="biab_bet-matched" style={{position:' fixed', top: '300px', zIndex: '9999', display: 'block'}}>
                     <div class="biab_matching-bet-msg biab_visible-xs js-matching-bet-msg biab_type-bet biab_bet-status-matched biab_type-bet-back" style={{backgroundColor:'red'}}><span class="biab_matched-label js-matched-label"></span>
                         <p class="biab_matched-details js-matched-details" style={{textAlign:"center"}}>Cannot place bet. Bet Blocked.</p>
                     </div>
                 </div>
             </div>}
             {props.msgNumber === 4 &&
                 <div class="js-bet-message-wrapper">
                 <div class="biab_bet-matched" style={{position:' fixed', top: '300px', zIndex: '9999', display: 'block'}}>
                     <div class="biab_matching-bet-msg biab_visible-xs js-matching-bet-msg biab_type-bet biab_bet-status-matched biab_type-bet-back" style={{backgroundColor:'red'}}><span class="biab_matched-label js-matched-label"></span>
                         <p class="biab_matched-details js-matched-details" style={{textAlign:"center"}}>Cannot place bet. Bet closed.</p>
                     </div>
                 </div>
             </div>}
             {props.msgNumber === 5 &&
                 <div class="js-bet-message-wrapper">
                 <div class="biab_bet-matched" style={{position:' fixed', top: '300px', zIndex: '9999', display: 'block'}}>
                     <div class="biab_matching-bet-msg biab_visible-xs js-matching-bet-msg biab_type-bet biab_bet-status-matched biab_type-bet-back" style={{backgroundColor:'red'}}><span class="biab_matched-label js-matched-label"></span>
                         <p class="biab_matched-details js-matched-details" style={{textAlign:"center"}}>Cannot place bet.Insufficient Coin.</p>
                     </div>
                 </div>
             </div>}
             {props.msgNumber === 6 &&
                 <div class="js-bet-message-wrapper">
                 <div class="biab_bet-matched" style={{position:' fixed', top: '300px', zIndex: '9999', display: 'block'}}>
                     <div class="biab_matching-bet-msg biab_visible-xs js-matching-bet-msg biab_type-bet biab_bet-status-matched biab_type-bet-back" style={{backgroundColor:'red'}}><span class="biab_matched-label js-matched-label"></span>
                         <p class="biab_matched-details js-matched-details" style={{textAlign:"center"}}>Cannot place bet. Odds Changed.</p>
                     </div>
                 </div>
             </div>}
             {props.msgNumber === 7 &&
                 <div class="js-bet-message-wrapper">
                 <div class="biab_bet-matched" style={{position:' fixed', top: '300px', zIndex: '9999', display: 'block'}}>
                     <div class="biab_matching-bet-msg biab_visible-xs js-matching-bet-msg biab_type-bet biab_bet-status-matched biab_type-bet-back" style={{backgroundColor:'red'}}><span class="biab_matched-label js-matched-label"></span>
                         <p class="biab_matched-details js-matched-details" style={{textAlign:"center"}}>Bet can not be placed!</p>
                     </div>
                 </div>
             </div>}
             {props.msgNumber === 8 &&
                 <div class="js-bet-message-wrapper">
                 <div class="biab_bet-matched" style={{position:' fixed', top: '300px', zIndex: '9999', display: 'block'}}>
                     <div class="biab_matching-bet-msg biab_visible-xs js-matching-bet-msg biab_type-bet biab_bet-status-matched biab_type-bet-back" style={{backgroundColor:'red'}}><span class="biab_matched-label js-matched-label"></span>
                         <p class="biab_matched-details js-matched-details" style={{textAlign:"center"}}>Enter valid coin value!</p>
                     </div>
                 </div>
             </div>}
             {props.msgNumber === 9 &&
                 <div class="js-bet-message-wrapper">
                 <div class="biab_bet-matched" style={{position:' fixed', top: '300px', zIndex: '9999', display: 'block'}}>
                     <div class="biab_matching-bet-msg biab_visible-xs js-matching-bet-msg biab_type-bet biab_bet-status-matched biab_type-bet-back" style={{backgroundColor:'red'}}><span class="biab_matched-label js-matched-label"></span>
                         <p class="biab_matched-details js-matched-details" style={{textAlign:"center"}}>Bet Over 75 not accepted!</p>
                     </div>
                 </div>
             </div>}
             {props.msgNumber === 10 &&
                 <div class="js-bet-message-wrapper">
                 <div class="biab_bet-matched" style={{position:' fixed', top: '300px', zIndex: '9999', display: 'block'}}>
                     <div class="biab_matching-bet-msg biab_visible-xs js-matching-bet-msg biab_type-bet biab_bet-status-matched biab_type-bet-back" style={{backgroundColor:'red'}}><span class="biab_matched-label js-matched-label"></span>
                         <p class="biab_matched-details js-matched-details" style={{textAlign:"center"}}>Bet rejected!</p>
                     </div>
                 </div>
             </div>}
             {props.msgNumber === 11 &&
                 <div class="js-bet-message-wrapper">
                 <div class="biab_bet-matched" style={{position:' fixed', top: '300px', zIndex: '9999', display: 'block'}}>
                     <div class="biab_matching-bet-msg biab_visible-xs js-matching-bet-msg biab_type-bet biab_bet-status-matched biab_type-bet-back" style={{backgroundColor:'red'}}><span class="biab_matched-label js-matched-label"></span>
                         <p class="biab_matched-details js-matched-details" style={{textAlign:"center"}}>Min bet is {props.stakeInfo.minStake}</p>
                     </div>
                 </div>
             </div>}
             {props.msgNumber === 12 &&
                 <div class="js-bet-message-wrapper">
                 <div class="biab_bet-matched" style={{position:' fixed', top: '300px', zIndex: '9999', display: 'block'}}>
                     <div class="biab_matching-bet-msg biab_visible-xs js-matching-bet-msg biab_type-bet biab_bet-status-matched biab_type-bet-back" style={{backgroundColor:'red'}}><span class="biab_matched-label js-matched-label"></span>
                         <p class="biab_matched-details js-matched-details" style={{textAlign:"center"}}>Max bet is {props.stakeInfo.maxStake}</p>
                     </div>
                 </div>
             </div>}
        </React.Fragment>
    )
}
