import React, { useEffect, useState, useRef } from 'react';
import Header from './Header';
import Inplay from './Inplay';
import Home from './Home';
import DasboardDemo from './DasboardDemo';
import Cookies from 'universal-cookie';
import Sport from './Sport';
import Inplaymatches from './Inplaymatches';
import { toast } from 'react-toastify'
import { suid } from 'rand-token';
import axios from 'axios';
import Rules from './Rules';
import Ledger from './Ledger';
import Statement from './Statement';
import Match_bet_status from './Match_bet_status';
import Setting from './Setting';
import Password from './Password';
import LoginApp from './LoginApp';
import TVStremaing from './TVStreaming';
import Casino from './Casino';
import Stackbet from './Stackbet';
import SettingStack from './SettingStack';
import BetplaceMsg from './BetplaceMsg';
import useOutsideClick from "./useOutsideClick";
import { Newsbar } from './Newsbar';



const cookies = new Cookies();
window.flag = 0;
var cc;
var tt;


export default function Combine(props) {

  var eventId = null;
  var marketId = null;
  var eventT = 2;
  if (props.match.params.eventT === '4' || props.match.params.eventT === '1' || props.match.params.eventT === '2') {
    eventId = props.match.params.eventId;
    marketId = props.match.params.marketId;
    eventT = 0;
  }

  if (props.match.params.eventType === "DasboardDemo") { eventT = 2; }
  else if (props.match.params.eventType === "Password") { eventT = 3; }
  else if (props.match.params.eventType === "Sport") { eventT = 4; }
  else if (props.match.params.eventType === "inplay") { eventT = 5; }
  else if (props.match.params.eventType === "Ledger") { eventT = 6; }
  else if (props.match.params.eventType === "Match_bet_status") { eventT = 7; }
  else if (props.match.params.eventType === "Rules") { eventT = 8; }
  else if (props.match.params.eventType === "Statement") { eventT = 10; }
  else if (props.match.params.eventType === "Setting") { eventT = 11; }
  else if (props.match.params.eventType === "Home") { eventT = 12; }
  else if (props.match.params.eventType === "casino") { eventT = 13; }
  else if (props.match.params.eventType === "stackvalue") { eventT = 14; }

  const [Tab, settab] = useState(eventT);
  const [Loginapp, setLogin] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(null);
  const [userid, setId] = useState("");
  const [balance, setBalance] = useState(0);
  const [exposure, setExposure] = useState(0);
  const [isError, setIsError] = useState(false);
  const [showFullMarket, setFullMarket] = useState(false);
  const [Tvopen, setTvopen] = useState(false)
  const [liveStream, setliveStream] = useState(null);
  const [SHowLogin, setSHowLogin] = useState(false)
  const [msgNumber, setMsgNumber] = useState(0);
  const [betInfo, setbetInfo] = useState({});
  const [stakeInfo, setstakeInfo] = useState({});
  const [FancyRulespopup, setFancyRulespopup] = useState(false)
  const [refreshhh, setrefreshhh] = useState(false);
  const [refreshMsg, setrefreshMsg] = useState('Loading...')
  const [isShown, setisShown] = useState(false);
  const [ClickOpen, setClickOpen] = useState(false)


  const [Dropdownop, setwrapDisplayOpen] = useState(false);
  const [show, setSate] = useState(false);
  const [open, setopen] = useState(false);
  const ref = useRef();
  const showLogin = (val) => {
    setLogin(val)
  }
  const handleRefreshhh = (t = 1000, msg = 'Loading...', flag = 0) => {
    if (flag === 1) {
      setrefreshhh(true);
      setrefreshMsg(msg);
    }
    else if (flag === 2) {
      setrefreshhh(false);

    }
    else {
      setrefreshhh(true);
      setrefreshMsg(msg)
      setTimeout(() => { setrefreshhh(false); }, t);
    }
  }

  const ClicktoOpen = () => {
    setClickOpen(true)
  }
  const ClicktoClose = () => {
    setClickOpen(false)
  }
  const changeMsgNumber = (val) => {
    setMsgNumber(val);
  }

  const changeBetInfo = (obj) => {
    var obj2 = obj;
    setbetInfo(obj2);
  }

  const changeStakeInfo = (obj) => {
    var obj2 = obj;
    setstakeInfo(obj2);
  }

  const hnadlefancyrules = () => {
    setFancyRulespopup(true)
  }
  const changeLiveStreaming = (val) => {
    setliveStream(val);
    setTvopen(true);

  }

  const changeState = (obj) => {
    var obj1 = true;
    obj1 = obj;
    setFullMarket(obj1);
    changeTab(0);
  };

  const changeTab = (val) => {
    settab(val);
  }

  const checkShowLogin = (bool) => {
    setSHowLogin(bool);
  }
  const changeExposure = (obj) => {
    setExposure(obj);
  }

  const changeBalance = (obj) => {
    setBalance(obj);
  }


  const TvControl = () => {
    setisShown(true);
    setTimeout(() => {
      setisShown(false)
    }, 2700);
  }

  const ChangeTv = () => {
    setTvopen(true)
    TvControl();
  }
  const ChangeSetTv = () => {
    setTvopen(!Tvopen)
  }

  useEffect(() => {
    if (cookies.get('token') == null) {
      setLoggedIn(false);
      return;
    }
    else {
      var utoken = cookies.get('token');
      tt = utoken;
      var user = cookies.get('id');
      setId(user);
      axios.post('https://waveapi.in/api/client/clientrefresh', {
        id: user,
        token: utoken
      })
        .then(result => {
          if (result.status === 210) {
            console.log('refresh successful');
            setLoggedIn(true);
            setBalance(result.data[0].val - Math.abs(result.data[1].val));

            if (result.data[1].val) {
              setExposure(Math.abs(result.data[1].val));
            }
            else {
              setExposure(0);

            }

          }

          if (result.status === 220) {
            console.log('refresh unsucessful');
            setLoggedIn(false);

          }
        }

        ).catch(e => {
          setIsError(true);
        });
    }
  }, []);



  useEffect(() => {

    settab(eventT)
  }, [eventT])

  const handleLogout = () => {
    cookies.remove('id', { path: '/' });
    cookies.remove('token', { path: '/' });
    settab(3);
    setTimeout(() => { window.location.reload(); }, 200);
  }
  // const handleSidebarcloase = () => {
  //   setopen(false)
  // }
  // const handleDropdown = () => {
  //   setSate(!show)
  //   handleSidebarcloase();

  // }
  // useOutsideClick(ref, () => {
  //   if (show) setSate(false);
  // });




  const handleSidebar = (Dropdown) => {
    setopen(Dropdown)
  }
  const handleSidebarcloase = () => {

    setopen(false)
  }
  const handleDropdown = () => {
    setSate(!show)
  }
  useOutsideClick(ref, () => {
    if (show) setSate(false);
  });
  const navoff = () => {
    handleSidebarcloase(false);

  }



  return (
    <React.Fragment>

      {isLoggedIn === false && <LoginApp showLogin={showLogin} />}
      {isLoggedIn &&
        <div class="ledger-body" style={{ height: '100%', backgroundColor: 'rgb(233, 233, 233)' }}>
          {<React.Fragment ref={ref} >
            <Header navoff={navoff} Tab={Tab} handleSidebar={handleSidebar} open={open} show={show} handleDropdown={handleDropdown} handleSidebarcloase={handleSidebarcloase} userid={userid} utoken={tt} exposure={exposure} balance={balance} handleLogout={handleLogout} />
            <Newsbar userid={userid} utoken={tt} />
            {Tab === 2 && isLoggedIn === true && <DasboardDemo />}
            {Tab === 3 && isLoggedIn === true && <Password userid={userid} utoken={tt} />}
            {Tab === 4 && isLoggedIn === true && <Sport userid={userid} utoken={tt} eventId={eventId} changeState={changeState} />}
            {Tab === 5 && isLoggedIn === true && <Inplay changeState={changeState} />}
            {Tab === 6 && isLoggedIn === true && <Ledger userid={userid} utoken={tt} />}
            {Tab === 7 && isLoggedIn === true && <Match_bet_status userid={userid} utoken={tt} eventId={eventId} />}
            {Tab === 8 && isLoggedIn === true && <Rules />}
            {Tab === 0 && isLoggedIn === true && <Inplaymatches refreshhh={refreshhh} ChangeSetTv={ChangeSetTv} Tvopen={Tvopen} Tab={Tab} isLoggedIn={isLoggedIn} liveStream={liveStream} changeLiveStreaming={changeLiveStreaming} isLoggedIn={isLoggedIn} checkShowLogin={checkShowLogin} changeExposure={changeExposure} changeBalance={changeBalance} changeStakeInfo={changeStakeInfo} changeBetInfo={changeBetInfo} changeMsgNumber={changeMsgNumber} userid={userid} utoken={tt} handleRefreshhh={handleRefreshhh} hnadlefancyrules={hnadlefancyrules} TvControl={TvControl} />}
            <BetplaceMsg stakeInfo={stakeInfo} betInfo={betInfo} msgNumber={msgNumber} changeMsgNumber={changeMsgNumber} refreshMsg={refreshMsg} />
            {Tab === 10 && isLoggedIn === true && <Statement userid={userid} utoken={tt} />}
            {Tab === 11 && isLoggedIn === true && <Setting userid={userid} utoken={tt} />}
            {Tab === 12 && isLoggedIn === true && <Home />}
            {Tab === 13 && isLoggedIn === true && <Casino />}
            {Tab === 14 && isLoggedIn === true && <SettingStack userid={userid} utoken={tt} />}

          </React.Fragment>
          }
        </div>}
    </React.Fragment>
  )
}
