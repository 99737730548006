import React,{useState} from 'react'

export default function Rules() {
  const [Onappear, setOnappear] = useState(0);

    return (        
        <React.Fragment>
        <div class="rule_main_div">
       
             <div  onClick ={()=>{setOnappear(0)}} style={Onappear === 0 ?{backgroundColor:'#983838'}:{backgroundColor:'#bdbdbd',color:'#fff'}} class="rule_hindi">Hindi</div> 
             <div  onClick ={()=>{setOnappear(1)}} style={Onappear === 1 ?{backgroundColor:'#983838'}:{backgroundColor:'#bdbdbd',color:'#fff'}} class="rule_english">English</div>   
        </div>
        {Onappear === 0 &&
        <div>
        {/* <div  class="rule_text_upper">
          <p >🏏 !! BSF1010 में आपका स्वागत है !! 🏏</p>
          <br/>
          <p>नोट :- BSF1010.co , BSF9999.com , BSF5555.com , BSF0000.com , BSF1010.com, GoodBet7777.com, BSF8888.com, SKY7777.co ये सभी आयडी BSF ग्रुप के आयडी है बाकी जो भी है BSF के नाम से वोह सभी Dummy है</p>
          <br/>
          <p>नोट :- BSF के सभी ID मैं रूले, इनसाइड आउटसाइड(अंदर-बाहर), सिंगल डिजिट(दस का दम), और ट्रिपल चांस ये सभी लाइव गेम इन-प्ले हो गए हे| आपको गेम के आवश्यकता हो तो आप अपने एजेंट से संपर्क करे| BSF GROUP</p>
        </div> */}
        <br/>
        <div >
            <ol class="rule_text_lower" >
            <li>1. कृपया BSF1010 के नियमों को समझने के लिए यहां कुछ मिनट दें, और अपने अनुसार समझ लें | </li>
            <li>2. लॉग इन करने के बाद अपना पासवर्ड बदल लें |</li>
            <li>3. प्रत्येक गेम के लिए 15.0/- कॉइन्स चार्ज रहेगा |</li>
            <li>4. यदि आप मैच या सेशन का एक भी सौदा नहीं करते हो, ऐसे में आपसे 15.0/- कॉइन्स का चार्ज लिया जायेगा |</li>
            <li>5. सभी एडवांस सौदे टॉस के बाद लिए जाएंगे |</li>
            <li>6. खेल रद्द या टाई होने पर सभी सौदे रद्द कर दिए जाएंगे और लेनदेन सेशन और फैंसी जो पूरा हो गया है उस पर किया जाएगा |</li>
            <li>7. मैच के लिए सौदे की रकम:</li>
            <li>8. मैच
                मार्केट का प्रकार	कम से कम शर्त राशि	अधिकतम शर्त राशि
                मैच कौन जीतेगा	500	1000000
                क्रिकेट सेशन	500	220000
                क्रिकेट एकी बेकी	100	100000
                अंक समाप्त मैच का दांव	100	100000</li>
            <li>9. Leicestershire vs Yorkshire
                मार्केट का प्रकार	कम से कम शर्त राशि	अधिकतम शर्त राशि
                मैच कौन जीतेगा	500	500000
                क्रिकेट सेशन	500	110000
                क्रिकेट एकी बेकी	100	100000
                अंक समाप्त मैच का दांव	100	100000</li>
            <li>10.Madurai Panthers vs Ruby Trichy Warriors
                मार्केट का प्रकार	कम से कम शर्त राशि	अधिकतम शर्त राशि
                मैच कौन जीतेगा	500	500000
                क्रिकेट सेशन	500	110000
                क्रिकेट एकी बेकी	100	100000
                अंक समाप्त मैच का दांव	100	100000</li>
            <li>11. एकी बेकी में 95 का भाव है |</li>
            <li>12. लॉटरी ड्रॉ में 9.5 का भाव है |</li>
            <li>13. लाइव ड्रा टीवी स्कोर पर निर्भर है | दर कभी नहीं बदली जाती है | यह यूजर के लिए अच्छा मौका है |</li>
            <li>14. मैच के दौरान भाव को देख और समझ कर ही सौदा करें | किये गए किसी भी सौदे को हटाया या बदला नहीं जायेगा | सभी सौदे के लिए आप स्वयं जिम्मेवार हैं |</li>
            <li>15. यहाँ सभी सौदे लेजर से मान्य किये जायेंगे |</li>
            <li>16. इंटरनेट कनेक्शन प्रॉब्लम की जिम्मेवारी आपकी रहेगी |</li>
            <li>17. नोट: सर्वर या वेबसाइट में किसी तरह की खराबी आने या बंद हो जाने पर केवल किए गए सौदे ही मान्य होंगे | ऐसी स्तिथि में किसी तरह का वाद-विवाद मान्य नहीं होगा |</li>
            </ol>
        </div>
        </div>} 

        {Onappear === 1 &&
      <div>
        {/* <div  class="rule_text_upper">
          <p >🏏 !! Welcome To BSF1010 !! 🏏</p>
          <br/>
          <p>Note :- BSF1010.co , BSF9999.com , BSF5555.com , BSF0000.com , BSF1010.com, GoodBet7777.com, BSF8888.com, SKY7777.co Yee Sabhi id BSF GROUP ke id hai Baki jo bhi id hai BSF ke nam se woh Sabhi id Dummy hai</p>
          <br/>
          <p>Note :- BSF Ke Sabhi ID Me Roulette, Inside Outside(Andar Bahar), Single Digit(Dus ka Dum), & Triple Chance Yee sabhi Live Game inplay Ho gay hai aapko Game ke Requirement ho tho aap apne Agent se Contact kare. BSF GROUP</p>
        </div> */}
        <ul class="rule_text_lower" >
            <li>1.  Please give a few minutes to understand rules of BSF1010 here, as best as you can. </li>
            <li>2.  Change your password after you log in</li>
            <li>3.  For every Match 15.0/- coins will be charged.</li>
            <li>4.  15.0/- coins will be charged if user will not play any Market bet or Session bet in a match.</li>
            <li>5.  All the advance bets will be accepted after the toss.</li>
            <li>6.  If game is cancelled or tie then all the deals will be cancelled and the transactions will be done on session and fancy which are completed.</li>
            <li>7.  The deal of the match is at least 500.0 and maximum 100000.0 and the deal of session is at least 100.0 and maximun 55000.0.</li>
            <li>8.  Even Odd has rate of 95.</li>
            <li>10. Lottery draw has rate of 9.5</li>
            <li>11. Live draw is settled on TV score, rate is never changed. This is good chance for users.</li>
            <li>12. During the match, please bet only after confirming the deal. Once the deal is confirmed, it cannot be changed or removed. Responsibility of every deal is yours.</li>
            <li>13. All transactions will be validated from ledger only.</li>
            <li>14. It'll be user's responsibility for internet connection problem.</li>
            <li>15. Note: If some kind of breakdown occurs in server or website, only successful bets will be accepted. In such situation, any kind of debate will be invalid.</li>
                           
        </ul>
        </div>}
       
        </React.Fragment>
    )
}
