import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment'

export const Newsbar = (props) => {

  const [Announcement, setAnnouncement] = useState([])
  const [DateNew, setDateNew] = useState([])

  useEffect(() => {
   
      axios.post('https://waveapi.in/api/client/clientnews',{
         id:props.userid,
         token:props.utoken,
        
       })
       .then(result => {
        if(result.status === 210){
			 
         
                  setAnnouncement(result.data[0].message);
                  setDateNew(result.data[0].date);
          }
     
    }
      ).catch(e => {
        //setIsError(true);
      });

   }, [])

    return (
        <React.Fragment>
         <ul className="marquee">
  <marquee>
    <li id="marqmessage">{DateNew} {Announcement}</li>
  </marquee>
</ul>
   
        </React.Fragment>
    )
}
