import React from 'react'
import Combine from './Combine'
import './home.css'
import {BrowserRouter as Router,Switch, Route,} from "react-router-dom";
import './Apply.css'


export default function App() {
  const loc = window.location.href;
  const param = loc.split( '/' );
  
  const path = param[3];
  console.log(path);
  
  return (
    <React.Fragment>
      <Router>
            <Switch>
                <Route exact path="/" component={Combine}></Route> 
                <Route exact path="/:eventType" component={Combine}></Route>
                <Route exact path="/:eventType/:eventId/" component={Combine}></Route> 
                <Route exact path='/:eventType/:eventId/:subId/' component={Combine}></Route>
                <Route exact path='/:Inplaymatches/:eventT/:eventId/:marketId' component={Combine}></Route>
          </Switch>
         </Router>
    </React.Fragment>
  )
}  