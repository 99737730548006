import axios from 'axios';
import React, { useEffect, useState } from 'react'



export default function Match_bet_status(props) {
    const path = window.location.href;
    const param = path.split('/');

    const event_id = param[4];
    const PL = param[5];
    const [PtofitLosff, setPtofitLosff] = useState([])
    const [Fancy, setFancy] = useState([]);
    const [Matchodds, setMatchodds] = useState([])


    useEffect(() => {
        // if (!props.eventId) { return; }
        axios.post("https://waveapi.in/api/client/clienteventprofitloss", {
            eventId: event_id,
            id: props.userid,
            token: props.utoken
        })
            .then((result) => {
                setPtofitLosff(result.data)
                var fancy = [], Match = [];
                for (var i = 0; i < result.data.length; i++) {
                    if (result.data[i].betType == 'fancy') fancy.push(result.data[i]);
                    if (result.data[i].betType == 'match') Match.push(result.data[i]);

                }
                setFancy(fancy);
                setMatchodds(Match);




            }).catch((e) => { });

    }, []);

    return (
        <React.Fragment>
            <div>


                <div class="Match_status_header">Match Winner Market Bet</div>
                <div className="container ledger-table-container-3">
                    <div className="row content-top-padding" style={{ paddingBottom: '15px', width: '100%', marginLeft: '0px' }}>

                        <table className="table table-bordered header-top-spacing"  >
                            <thead>
                                <tr>
                                    <th className="ldg-tbl-th match-box-color" >Rate</th>
                                    <th className="ldg-tbl-th match-box-color" >Amt</th>
                                    <th className="ldg-tbl-th match-box-color" >Mode</th>
                                    <th className="ldg-tbl-th match-box-color" >Team</th>
                                    <th className="ldg-tbl-th match-box-color" ><span> Result  </span></th>
                                    <th className="ldg-tbl-th match-box-color" ><span> P&L</span></th>

                                </tr>
                            </thead>
                            {Matchodds.map(function (item, id) {
                                var tname, res;
                                if (item.teamName == "A") tname = item.runnerName1;
                                else if (item.teamName == "B") tname = item.runnerName2;
                                else tname = "Draw"
                                if (item.winner == "A") res = item.runnerName1;
                                else if (item.winner == "B") res = item.runnerName2;
                                else res = "Draw"
                                tname = (tname.substring(0, 3));
                                res = (res.substring(0, 3));





                                var playerName;
                                var odds;
                                var matchtype;
                                var profit = '';
                                if (item.betType === 'match') {
                                    odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
                                    if (item.teamName === 'A') {
                                        playerName = item.runnerName1;

                                    }
                                    else if (item.teamName === 'B') {
                                        playerName = item.runnerName2;
                                    }
                                    else if (item.teamName === 'T') {
                                        playerName = 'The Draw';
                                    }
                                }
                                else if (item.betType === 'fancy') {
                                    playerName = item.runnerName;
                                    odds = item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0);
                                }

                                if (item.type === 'LAGAI') {
                                    matchtype = 'BACK';
                                }
                                else if (item.type === 'KHAI') {
                                    matchtype = 'LAY';
                                }
                                else {
                                    matchtype = item.type;
                                }


                                if (item.betType == 'fancy' && item.result) {
                                    if (item.type == "YES") {
                                        if (parseFloat(item.rate) <= parseFloat(item.result)) {
                                            profit = parseFloat(item.amount * item.teamName).toFixed(2);
                                        }
                                        else profit = parseFloat(item.amount * (-1)).toFixed(2);
                                    }
                                    else {
                                        if (parseFloat(item.rate) > parseFloat(item.result)) {
                                            profit = parseFloat(item.amount).toFixed(2);
                                        }
                                        else profit = parseFloat((item.amount * item.teamName) * (-1)).toFixed(2);
                                    }
                                }
                                else if (item.betType == 'match' && item.winner) {
                                    if (item.type == "LAGAI") {
                                        if (item.teamName == 'A') {
                                            if (item.winner == "A") profit = parseFloat(item.rate * item.amount).toFixed(2);
                                            else profit = parseFloat(item.amount * (-1)).toFixed(2);
                                        }
                                        else if (item.teamName == "B") {
                                            if (item.winner == "B") profit = parseFloat(item.rate * item.amount).toFixed(2);
                                            else profit = parseFloat(item.amount * (-1)).toFixed(2);
                                        }
                                        else if (item.teamName == "T") {
                                            if (item.winner == "T") profit = parseFloat(item.rate * item.amount).toFixed(2);
                                            else profit = parseFloat(item.amount * (-1)).toFixed(2);
                                        }
                                    }
                                    else if (item.type == "KHAI") {
                                        if (item.teamName == 'A') {
                                            if (item.winner != "A") profit = parseFloat(item.amount).toFixed(2);
                                            else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
                                        }
                                        else if (item.teamName == "B") {
                                            if (item.winner != "B") profit = parseFloat(item.amount).toFixed(2);
                                            else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
                                        }
                                        else if (item.teamName == "T") {
                                            if (item.winner != "T") profit = parseFloat(item.amount).toFixed(2);
                                            else profit = parseFloat(item.rate * item.amount * -1).toFixed(2);
                                        }
                                    }
                                    if ((item.runnerId3 == null || item.runnerId3 == '' || item.runnerId3 == ' ') && item.winner == "T") {
                                        profit = 0;
                                    }
                                }




                                return (
                                    <thead key={id}>
                                        <tr>
                                            <th className="ldg-tbl-th match-box-color">{item.rate}</th>
                                            <th className="ldg-tbl-th match-box-color">{item.amount}</th>
                                            <th className="ldg-tbl-th match-box-color">{item.type}</th>
                                            <th className="ldg-tbl-th match-box-color">{tname}</th>
                                            <th className="ldg-tbl-th match-box-color"><span> {res}  </span></th>
                                            <th className="ldg-tbl-th match-box-color"><span >{profit >= 0 ? '+'+profit : '-' + Math.abs(profit).toFixed(2)}</span></th>

                                        </tr>
                                    </thead>
                                )
                            })}


                        </table>



                    </div>
                </div>

                <div class="Match_status_header">Fancy Bet</div>
                <div className="container ledger-table-container-3">
                    <div className="row content-top-padding" style={{ paddingBottom: '15px', width: '100%', marginLeft: '0px' }}>

                        <table className="table table-bordered header-top-spacing"  >
                            <thead>
                                <tr>
                                    <th className="ldg-tbl-th match-box-color" >Fancy</th>
                                    <th className="ldg-tbl-th match-box-color" >Run</th>
                                    <th className="ldg-tbl-th match-box-color" >Rate</th>
                                    <th className="ldg-tbl-th match-box-color" >Amt</th>
                                    <th className="ldg-tbl-th match-box-color" >Mode</th>
                                    <th className="ldg-tbl-th match-box-color" ><span> Result  </span></th>
                                    <th className="ldg-tbl-th match-box-color" ><span> P&L</span></th>

                                </tr>
                            </thead>
                            
                            {Fancy.map(function (item2, id2) {
                                var tname, res;
                                if (item2.teamName == "A") tname = item2.runnerName1;
                                else if (item2.teamName == "B") tname = item2.runnerName2;
                                else tname = "Draw"
                                if (item2.winner == "A") res = item2.runnerName1;
                                else if (item2.winner == "B") res = item2.runnerName2;
                                else res = "Draw"
                                tname = (tname.substring(0, 3));
                                res = (res.substring(0, 3));

                                var playerName;
                                var odds;
                                var matchtype;
                                var profit = '';
                                if (item2.betType === 'match') {
                                    odds = parseFloat(parseFloat(item2.rate) + 1).toFixed(2);
                                    if (item2.teamName === 'A') {
                                        playerName = item2.runnerName1;

                                    }
                                    else if (item2.teamName === 'B') {
                                        playerName = item2.runnerName2;
                                    }
                                    else if (item2.teamName === 'T') {
                                        playerName = 'The Draw';
                                    }
                                }
                                else if (item2.betType === 'fancy') {
                                    playerName = item2.runnerName;
                                    odds = item2.rate + '/' + parseFloat(item2.teamName * 100).toFixed(0);
                                }

                                if (item2.type === 'LAGAI') {
                                    matchtype = 'BACK';
                                }
                                else if (item2.type === 'KHAI') {
                                    matchtype = 'LAY';
                                }
                                else {
                                    matchtype = item2.type;
                                }


                                if (item2.betType == 'fancy' && item2.result) {
                                    if (item2.type == "YES") {
                                        if (parseFloat(item2.rate) <= parseFloat(item2.result)) {
                                            profit = parseFloat(item2.amount * item2.teamName).toFixed(2);
                                        }
                                        else profit = parseFloat(item2.amount * (-1)).toFixed(2);
                                    }
                                    else {
                                        if (parseFloat(item2.rate) > parseFloat(item2.result)) {
                                            profit = parseFloat(item2.amount).toFixed(2);
                                        }
                                        else profit = parseFloat((item2.amount * item2.teamName) * (-1)).toFixed(2);
                                    }
                                }
                                else if (item2.betType == 'match' && item2.winner) {
                                    if (item2.type == "LAGAI") {
                                        if (item2.teamName == 'A') {
                                            if (item2.winner == "A") profit = parseFloat(item2.rate * item2.amount).toFixed(2);
                                            else profit = parseFloat(item2.amount * (-1)).toFixed(2);
                                        }
                                        else if (item2.teamName == "B") {
                                            if (item2.winner == "B") profit = parseFloat(item2.rate * item2.amount).toFixed(2);
                                            else profit = parseFloat(item2.amount * (-1)).toFixed(2);
                                        }
                                        else if (item2.teamName == "T") {
                                            if (item2.winner == "T") profit = parseFloat(item2.rate * item2.amount).toFixed(2);
                                            else profit = parseFloat(item2.amount * (-1)).toFixed(2);
                                        }
                                    }
                                    else if (item2.type == "KHAI") {
                                        if (item2.teamName == 'A') {
                                            if (item2.winner != "A") profit = parseFloat(item2.amount).toFixed(2);
                                            else profit = parseFloat(item2.rate * item2.amount * -1).toFixed(2);
                                        }
                                        else if (item2.teamName == "B") {
                                            if (item2.winner != "B") profit = parseFloat(item2.amount).toFixed(2);
                                            else profit = parseFloat(item2.rate * item2.amount * -1).toFixed(2);
                                        }
                                        else if (item2.teamName == "T") {
                                            if (item2.winner != "T") profit = parseFloat(item2.amount).toFixed(2);
                                            else profit = parseFloat(item2.rate * item2.amount * -1).toFixed(2);
                                        }
                                    }
                                    if ((item2.runnerId3 == null || item2.runnerId3 == '' || item2.runnerId3 == ' ') && item2.winner == "T") {
                                        profit = 0;
                                        //console.log(profit,'true');
                                    }
                                }




                                return (
                                    <thead key={id2}>
                                        <tr>
                                            <th className="ldg-tbl-th match-box-color" >{item2.runnerName}</th>
                                            <th className="ldg-tbl-th match-box-color">{item2.rate}</th>
                                            <th className="ldg-tbl-th match-box-color">{item2.teamName}</th>
                                            <th className="ldg-tbl-th match-box-color">{item2.amount}</th>
                                            <th className="ldg-tbl-th match-box-color">{item2.type}</th>
                                            <th className="ldg-tbl-th match-box-color"><span> {item2.result}  </span></th>
                                            <th className="ldg-tbl-th match-box-color"><span >{profit >= 0 ? '+'+profit : '-' + Math.abs(profit).toFixed(2)}</span></th>

                                        </tr>
                                    </thead>
                                )
                            })}


                        </table>



                    </div>
                </div>



               
                <div class="Match_status_header">Match Plus Minus</div>
                <div class="Match_status_heder_contained">You Win 0.0/- Coins</div>
                <div class="Match_status_header">Mob. App. Charge</div>
                <div class="Match_status_heder_contained">You Win 0.0/- Coins</div>
                <div class="Match_status_header">Net Plus Minus</div>
                <div class="Match_status_heder_contained">You Win {PL}/- Coins</div>
                <div class="Match_status_header">Back To List</div>


            </div>
        </React.Fragment>
    )
}


