import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

toast.configure(); 

export default function Password(props) {

    const [Newpassword, setNewpassword] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");
    const [password, setpassword] = useState("");

    const ChangePassword = (e) => {
        e.preventDefault();
        var demoid1 = 'demo01';
        //var demoid2 = 'demo02';
        if(props.userid.toUpperCase() === demoid1.toUpperCase()){
    
          toast.warn("Demo account password can't be changed", {
            position: toast.POSITION.TOP_CENTER,
          });
          return;
        }
        var strongRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}$/;
        var test = Newpassword.match(strongRegex);
    
        if (Newpassword === "" || confirmpassword === "" || password === "") {
          toast.warn("Password can not be Blank!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (Newpassword != confirmpassword) {
          toast.warn(" password is not matched!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (Newpassword === password) {
          toast.warn("Old password and new password is not same!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (!test) {
          toast.warn(
            "Password must have 8 to 15 alphanumeric without white space; cannot be the same as username/nickname; must contain at least 1 capital letter, small letter and numbers; and must not contain any special characters (!,@,#,etc..)",
            { position: toast.POSITION.TOP_CENTER }
          );
        } else {
          axios
            .post("https://waveapi.in/api/client/clientchangepassword", {
              id: props.userid,
              token: props.utoken,
              newPassword: Newpassword,
              oldPassword: password,
            })
            .then((result) => {
              if (result.status === 210) {
                toast.success("Password Changed Successfully", {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
              if (result.status === 220) {
                toast.warn("Your old password is wrong", {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            })
            .catch((e) => {
              //setIsError(true);
            });
        }
      };

    return (
        <React.Fragment>
            {/* <div class="col-sm-6 overall_page_streach" style={{float:'none',margin:'0 auto'}}>
                <div class=" PasswordForm">                
                  
                     <div class="change_password">Change Password</div>   
                    <Form.Group className="mb-3 formblock" controlId="formBasicEmail">
                        <Form.Label ><span class="formblock_inner">New Password</span></Form.Label>
                        <Form.Control type="text" placeholder="New password"  value={Newpassword} onChange={(e) => {setNewpassword(e.target.value);}} />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3 formblock" controlId="formBasicEmail">
                        <Form.Label ><span class="formblock_inner">Confirm New Password</span></Form.Label>
                        <Form.Control type="text" placeholder="Confirm New Password"  value={confirmpassword}
                onChange={(e) => {
                  setconfirmpassword(e.target.value);
                }}/>
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3 formblock" controlId="formBasicEmail">
                        <Form.Label ><span class="formblock_inner">Old Password</span></Form.Label>
                        <Form.Control type="text" placeholder="Old Password" value={password}
                onChange={(e) => {
                  setpassword(e.target.value);
                }}/>
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" style={{height:'90px', paddingTop:'10px',display:'flex',justifyContent:'center'}} controlId="formBasicEmail">
                        
                        <button   class="changepasswordsubmitbtn" onClick={(e) => { ChangePassword(e); }}>Save Change</button>
                    </Form.Group>
                    
                
                </div>

            </div> */}
            <div className="container chng-pass-container">
    <div className="row content-top-padding" style={{paddingTop: '13%'}}>
        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-10 col-centered login_stripe change-password-box-color">
            <form className="form-horixonatal" id="edit_user_4" noValidate="novalidate">
                <h1 className="login_heading" style={{fontFamily: "open-sans-semibold",fontWeight: "500"}}>Change Password</h1>
                <label id="invalid_old_password" style={{display: 'none'}}>Entered old Password is Wrong,please provide correct password.</label>
                <label id="invalid_new_password" style={{display: 'none'}}>Entered New password and confirm password must be same.</label>
                <div className="row">
                    <div className="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
                        <input type="password" id="password" name="password" className="form-control login_text_field" placeholder="OLD PASSWORD" /><br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
                        <input type="password" id="new_password" name="new_password" className="form-control login_text_field" placeholder="NEW PASSWORD" /><br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
                        <input type="password" id="password_confirmation" name="password_confirmation" className="form-control login_text_field" placeholder="CONFIRM PASSWORD" /><br />
                    </div>
                </div>
            </form>
            <div className="row" style={{    display: 'flex',justifyContent: 'space-between'}}>
                <div className="col-lg-12 col-md-12">
                    <button className="btn btn-danger purp_btn change_password_btn" id="create_bets">Done</button>
                </div>
            </div>
        </div>
    </div>
</div>
        </React.Fragment>

    )
}
