import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function Statement(props) {
    // const [accountStatement, setaccountStatement] = useState([])
    // const [Accountstatement, setAccountstatement] = useState([])
    // const [select, setselect] = useState(0)
    // const [bethistory, setbethistory] = useState([])
    // const [LedgerData, setLedgerData] = useState(moment().format("YYYY-MM-DD"));
    // const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
    // const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
    // const [startDate, setStartDate] = useState(moment().toDate());
    // const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
    // const [sTime, setsTime] = useState('09:00')
    // const [eTime, seteTime] = useState('08:59')
    // var globalArray1 = [];
    // useEffect(() => {

    //     axios.post('https://waveapi.in/api/client/clientaccountstatement', {
    //         id: props.userid,
    //         token: props.utoken,
    //     })
    //         .then(result => {
    //             setaccountStatement(result.data)
    //         }
    //         ).catch(e => {
    //             setIsError(true);
    //         });
    // }, [])


    // const GetLedgerData = () => {

    //     var start = '';
    //     var end = '';

    //     if (window.day === 3) {
    //         var now = moment().subtract(7, 'days');
    //         start = now.startOf('day').format("YYYY-MM-DD");
    //         end = moment().endOf('day').format("YYYY-MM-DD");
    //     }

    //     axios.post('https://waveapi.in/api/agent/LedugerReport', {
    //         id: props.userid,
    //         token: props.utoken,
    //         fromdate: start,
    //         todate: end,
    //     })

    //         .then(result => {
                
    //             if (result.data.length == 0) {
    //                 toast.warn('You have no bets in this periods!', { position: toast.POSITION.TOP_CENTER })
    //             }

    //             if (result.status === 200) {
    //                 setAccountstatement(result.data)
    //                 console.log(result.data)
    //                 // for (let key in result.data) {
    //                 //     if (result.data.hasOwnProperty(key)) {
    //                 //         result.data[key].user = key;
    //                 //         globalArray1.push(result.data[key]);
    //                 //     }
    //                 // }
    //                 // let arr = [];
    //                 // globalArray1.map((item) => {
    //                 //     arr.push(item);
    //                 // });
    //                 // setLedgerData(arr)
    //             }

    //         }

    //         ).catch(e => {
    //             //setIsError(true);
    //         });

    // }





    return (
        <React.Fragment>
              <div class="container matchList-matchBox-outer matchBox-outer">
             <div class="content-top-padding overall_page_streach"> 
             <div class="row alert-match-status-box" id="alert-status">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 alert-match-status-text">
                      <span>Comming Soon</span></div></div>
                </div>
          </div>
        </React.Fragment>
        // <div>
        //     <div class="statment_mainline">Statement</div>
        //     <div class="statment_mainline">
        //         <li style={{ listStyle: 'none' }}>

        //             <ul class="input-list" style={{ display: 'inline-flex' }}>
        //                 <input type='date' selected={startDate} onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }} />
        //                 <input type='date' selected={startDate} onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }} />

        //                 <li><a id="getPL" onClick={() => { window.day = 3; window.betHistoryView = 1; GetLedgerData(); }} class="btn-send" style={{ backgroundColor: '#64b267', color: '#fff', lineHeight: '24px', padding: '6px', fontSize: '12px', borderRadius: '4px' }}>Search</a></li>

        //             </ul>
        //         </li>
        //     </div>
            // <div class="desktop_mobile_width" >
            //     <div className="container ledger-table-container-3">
            //         <div className="row content-top-padding" style={{ paddingBottom: '15px' }}>
            //             <div style={{ overflowY: 'scroll', width: '150%' }} >
            //                 <table className="table table-bordered header-top-spacing"  >
            //                     <thead>
            //                         <tr>
            //                             <th className="ldg-tbl-th match-box-color" style={{ width: '20%' }}>Date</th>
            //                             <th className="ldg-tbl-th match-box-color" style={{ width: '25%' }}>Description</th>
            //                             <th className="ldg-tbl-th match-box-color" style={{ width: '15%' }}>Credit</th>
            //                             <th className="ldg-tbl-th match-box-color" style={{ width: '10%' }}>Debit</th>
            //                             <th className="ldg-tbl-th match-box-color" style={{ width: '10%' }}>Com+</th>
            //                             <th className="ldg-tbl-th match-box-color" style={{ width: '10%' }}>Com-</th>
            //                             <th className="ldg-tbl-th match-box-color" style={{ width: '10%' }}>Balance</th>

            //                         </tr>
            //                     </thead>
            //                     {Accountstatement.map(function (item, index) {
                                
                               
            //                     return (
            //                         <thead>
            //                             <tr>
            //                                 {/* <th className="ldg-tbl-th match-box-color" style={{ width: '20%', backgroundColor:'white', color:'black' }}>{item.timeCurr}</th>
            //                                <a href={`/Match_bet_status/${item.eventId}/${item.netPL}`}> <th className="ldg-tbl-th match-box-color" style={{ width: '35%', backgroundColor:'white', color:'#983838' }}>{item.eventName}</th></a>
            //                                 <th className="ldg-tbl-th match-box-color" style={{ width: '15%', backgroundColor:'white', color:'black' }}>{deb}</th>
            //                                 <th className="ldg-tbl-th match-box-color" style={{ width: '15%', backgroundColor:'white', color:'black' }}>{cr}</th>
            //                                 <th className="ldg-tbl-th match-box-color" style={{ width: '15%', backgroundColor:'white', color:'black' }}>{item.ledger_balance}</th> */}
            //                             </tr>
            //                         </thead>
            //                     )
            //                 })}
            //                     <tbody>
            //                     </tbody>
            //                 </table>

            //             </div>

            //         </div>
            //     </div>
                //  <div style={{ height: 'auto', width: '100%', overflowY: 'scroll' }}>
                //     <div class="statement_containtes"  >
                //         <li className="desth_border" style={{ width: '20%' }}>Date</li>
                //         <li className="desth_border" style={{ width: '25%' }}>Description</li>
                //         <li className="desth_border" style={{ width: '15%' }}>Credit</li>
                //         <li className="desth_border" style={{ width: '10%' }}>Debit</li>
                //         <li className="desth_border" style={{ width: '10%' }}>Com+</li>
                //         <li className="desth_border" style={{ width: '10%' }}>Com-</li>
                //         <li className="desth_border" style={{ width: '10%' }}>Balance</li>
                //     </div>



                //     {Accountstatement.map(function (item, id) {
                //         var obj1;
                //         var obj2;
                //         if (item.coin >= 0) {
                //             obj1 = parseFloat(item.coin).toFixed(2);
                //             obj2 = '-';
                //         }
                //         if (item.coin < 0) {
                //             obj1 = '-';
                //             obj2 = parseFloat(item.coin).toFixed(2);
                //         }
                //         var obj3 = parseFloat(item.fixLimit).toFixed(2); 
                //         return (
                //             <div class="statement_data statement_containtes" key={id}>
                //                 <li className="desth_border" style={{ width: '20%' }}>{item.date}</li>
                //                 <li className="desth_border" style={{ width: '25%' }}>{item.eventName}</li>
                //                 <li className="desth_border" style={{ width: '15%' }}>{item.Debit}</li>
                //                 <li className="desth_border" style={{ width: '10%' }}>{item.Credit}</li>
                //                 <li className="desth_border" style={{ width: '10%' }}>--</li>
                //                 <li className="desth_border" style={{ width: '10%' }}>--</li>
                //                 <li className="desth_border" style={{ width: '10%' }}>{item.balance}</li>
                //             </div>
                //         )
                //     })}
                // </div> 
        //     </div>
        // </div>
    )
}


