import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment'
import Loader from './Loader'
require('moment-timezone');


export default function TennisMatches(props) {
    const [match, setMatch] = useState([]);
    const [fancy, setFancy] = useState([]);
    const [newrefresh, setnewrefresh] = useState(true);
    const [tv, settv] = useState([])
    const [bookmaker, setbookmaker] = useState([])

    useEffect(() => {
    
        Axios.get(
          `https://waveapi.in/api/client/clienttennis`)
          
          .then(result => {
            result.data.map(item=>{
              item.marketStartTime = moment.tz(item.marketStartTime,'YYYY-MM-DD HH:mm:ss','Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
              return item;
            })
            
            result.data.sort(function(a,b){
             
             return moment(a.marketStartTime) - moment(b.marketStartTime);
            }); 
            setMatch(result.data); 
            if(newrefresh){
             setnewrefresh(false);
            }
            
            })
            
          
          .catch(error => console.log(error));
   
          Axios.get(
           `https://waveapi.in/api/client/clientchecktv`)
           
           .then(result => {
             
             settv(result.data); 
             })
           .catch(error => console.log(error));
           
       
               
   
      },[]);
    return (
        <React.Fragment>

{newrefresh === true &&
       <Loader/>}
       
   {match.map(function(item,id){
      var showColor = false;
      var showFancy = false;
      var isFancy = false;
      var showtv = false;
      var isbookmaker = false;


      var matchTime;
      var today = moment();
      var tommorow = moment().add(1, 'day'); 
      
      if(moment(item.marketStartTime).isSame(today, 'day')){
        matchTime = moment(item.marketStartTime).format('LLL');
      }
      else if(moment(item.marketStartTime).isSame(tommorow, 'day')){
        matchTime = 'Tommorow' + ' '+ moment(item.marketStartTime).format('LLL');;
      }
      else{
        matchTime = item.marketStartTime;
      }
      


      if(fancy.includes(item.eventId)){
        isFancy = true;
        
      }
      if(tv.includes(item.eventId)){
        showtv = true;
      }
      if(bookmaker.includes(item.eventId)){
        isbookmaker = true;
        
      }
    
      if(moment().diff(moment(item.marketStartTime),'minutes') >= 0){
        showColor = true;
        showFancy = true;
      }
      
    return (
      <React.Fragment key={id}>
        <Link to={`/Inplaymatches/2/${item.eventId}/${item.marketId}`} id="eventName"  onClick={()=>{props.changeState(true);}}>
                <div class="row matchBox-Row">
                {moment() >= moment(item.marketStartTime)  &&
                <div class="col-xs-12 top-time-dtl in-play-right-box-color" style={{color:'#26ff6f'}}>
                    Inplay
                </div>}

                {moment() < moment(item.marketStartTime) &&
                <div class="col-xs-12 top-time-dtl in-play-right-box-color">
                    {matchTime}
                </div>}
                <div class="col-md-10 col-lg-10 col-sm-9 col-xs-12 in-play-row-left" style={{padding: "0px 0px 0px 0px",height:'90px'}}>
                    <div class="col-lg-1 col-sm-2 col-xs-1 flags-container" style={{padding: "18px 0px 18px 0px"}}>

                    </div>
                    <div class="col-lg-11 col-sm-10 col-xs-8 match-detail-container" style={{padding: "15px 0px 0px 15px"}}>
                        <div class="match-title">
                            <p class="team-name-font-color">{item.eventName}</p>
                            <div class="match-info">
                            {/* <p class="match-summary-text">2nd Test</p> */}
                            <p>Match Bets : 0</p>
                            {/* <p>Session Bets : 0</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="col-md-2 col-lg-2 col-sm-3 col-xs-3 in-play-row-right in-play-right-box-color"  >
                        <div class="match-time-dtl">
                            {/* <p class="match-time-dtl-date">20</p> */}
                            <p class="match-time-dtl-mounth"> {matchTime}</p>
                            {/* <p class="match-time-dtl-mounth">
                            08:30 PM
                            </p> */}
                        </div>
                    </div>
                </div>
                </div>
                </Link>
              </React.Fragment>
              )})} 
        </React.Fragment>
    )
}
