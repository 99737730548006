import React from 'react'

export default function Casino() {
    return (
        <React.Fragment>
              <div class="container matchList-matchBox-outer matchBox-outer">
             <div class="content-top-padding overall_page_streach"> 
             <div class="row alert-match-status-box" id="alert-status">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 alert-match-status-text">
                      <span>Comming Soon</span></div></div>
                </div>
          </div>
        </React.Fragment>
    )
}
