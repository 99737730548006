import React, { useEffect, useState } from 'react'
import axios from 'axios'
import iconminus from './image/minus.png'
import iconplus from './image/pluss.png'
import deletebetnum from './image/delete.png'
import Cookies from 'universal-cookie';
import moment from 'moment'
import TV from "./image/tv_icon.png"
import OpenBets from './openBets'
import Mybets from './Mybets'
import TVStremaing from './TVStreaming'
import Loader from './Loader'
import CricketScore from './CricketScore'
import Cricketscoreboard from './Cricketscoreboard'
require('moment-timezone');

var qs = require('qs');
const cookies = new Cookies();


var matchEndedMarket = false;
var matchEndedBook = false;
cookies.set('dupBet', "2", { path: '/' });


const io = require('socket.io-client');

const ranNum = Math.floor(Math.random() * 4) + 1;
var ENDPOINT;
if (ranNum == 1) {
    ENDPOINT = "https://waveapi.in:2053";
}
else if (ranNum == 2) {
    ENDPOINT = "https://waveapi.in:2083";
}
else if (ranNum == 3) {
    ENDPOINT = "https://waveapi.in:2087";
}
else {
    ENDPOINT = "https://waveapi.in:2096";
}
var socket;

var c1 = 0, c2 = 0, c3 = 0, c4 = 0, c5 = 0, c6 = 0, c7 = 0, c8 = 0, c9 = 0, c10 = 0, c11 = 0, c12 = 0;
var s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24;




export default function Inplaymatches(props) {
    const [open, setopen] = useState(false);
    const [open_betslip, setopen_betslip] = useState(false);
    const [ssstake1, setSsstake1] = useState(0);
    const [ssstake2, setSsstake2] = useState(0);
    const [ssstake3, setSsstake3] = useState(0);
    const [ssstake4, setSsstake4] = useState(0);
    const [ssstake5, setSsstake5] = useState(0);
    const [ssstake6, setSsstake6] = useState(0);
    const [ssstake7, setSsstake7] = useState(0);
    const [ssstake8, setSsstake8] = useState(0);
    const [Maxstake, setMaxstake] = useState(false)
    const [Bookstake, setBookstake] = useState(false)
    const [Fancypin, setFancypin] = useState(false)
    const [bookpin, setbookpin] = useState(false)
    const [livemarpin, setlivemarpin] = useState(false)
    const [marketData, setmarketData] = useState({})
    const [fancyData, setfancyData] = useState([])
    const [defaultStake, setdefaultStake] = useState('');
    const [dStake, setdStake] = useState('');
    const [selectFancy, setselectFancy] = useState(null)
    const [bookData, setbookData] = useState({})
    const [selectFancyLay, setselectFancyLay] = useState(true)
    const [click, setclick] = useState(-1);
    const [bOdds, setbOdds] = useState(' ')
    const [mOdds, setmOdds] = useState(' ')
    const [oddsType, setoddsType] = useState('Back')
    const [teamName, setteamName] = useState('A')
    const [bookData2, setbookData2] = useState({})
    const [field, setfield] = useState(0);
    const [Bookclick, setBookclick] = useState(false)
    const [Bookclick2, setBookclick2] = useState(false)
    const [Bookclick3, setBookclick3] = useState(false)
    const [MatchoddsData, setMatchoddsData] = useState({})
    const [Matchoddsclick, setMatchoddsclick] = useState(false)
    const [Matchoddsclick2, setMatchoddsclick2] = useState(false)
    const [Matchoddsclick3, setMatchoddsclick3] = useState(false)
    const [selectMatchBack, setselectMatchBack] = useState(null)
    const [selectBack, setselectBack] = useState(null)
    const [selectLay, setselectLay] = useState(true)
    const [showConfirmBet, setshowConfirmBet] = useState(false)
    const [showFancyBook, setshowFancyBook] = useState(false)
    const [fancySelection, setfancySelection] = useState(null)
    const [fancyRunner, setfancyRunner] = useState(null)
    const [updateBet, setupdateBet] = useState(false)
    const [yesnoval, setyesnoval] = useState()
    const [khaeelagee, setkhaeelagee] = useState()
    const [runer12, setruner12] = useState()
    const [fOdds, setfOdds] = useState(' ')
    const [fsize, setfsize] = useState(' ')
    const [acceptAnyOdds, setacceptAnyOdds] = useState(false)

    const [runner1BackRate1, setrunner1BackRate1] = useState(' ');
    const [runner1BackSize1, setrunner1BackSize1] = useState(' ');
    const [runner1LayRate1, setrunner1LayRate1] = useState(' ');
    const [runner1LaySize1, setrunner1LaySize1] = useState(' ');
    const [runner2BackRate1, setrunner2BackRate1] = useState(' ');
    const [runner2BackSize1, setrunner2BackSize1] = useState(' ');
    const [runner2LayRate1, setrunner2LayRate1] = useState(' ');
    const [runner2LaySize1, setrunner2LaySize1] = useState(' ');

    const [tieBackRate1, settieBackRate1] = useState(' ');
    const [tieLayRate1, settieLayRate1] = useState(' ');
    const [tieBackSize1, settieBackSize1] = useState(' ');
    const [tieLaySize1, settieLaySize1] = useState(' ');
    const [pnlMarket, setpnlMarket] = useState({})
    const [pnlBook, setpnlBook] = useState({})
    const [pnlFancy, setpnlFancy] = useState([])

    const [pnlMarket2, setpnlMarket2] = useState({})
    const [pnlBook2, setpnlBook2] = useState({})
    const [ScoreData, setScoreData] = useState({})
    const [newrefresh, setnewrefresh] = useState(true);
    const [marketBetStatus, setmarketBetStatus] = useState(0);
    const [liquidityStatus, setliquidityStatus] = useState(0);
    const [fancyStakeInfo, setfancyStakeInfo] = useState(-1);
    const [coinFactor, setcoinFactor] = useState(1);




    // const handleSidebar = (Dropdown)=>{
    //   setopen(Dropdown)
    //   }
    //   const sessionbetslip = (session_betslip)=>{
    //     setopen_betslip(session_betslip)
    //     }


    const url = window.location.href;
    const para = url.split('/');

    const eventT = para[4];
    const eventid = para[5];
    const marketid = para[6];

    var matchType = '';
    if (eventT == 4) {
        matchType = 'Cricket';
    }
    else if (eventT == 1) {
        matchType = 'Soccer';
    }
    else if (eventT == 2) {
        matchType = 'Tennis';
    }

    useEffect(() => {
        socket = io(ENDPOINT);
        console.log('socket connected');
        socket.on('connect', function (data) {
            socket.emit('room1', eventid);
        });
   
        return () => {
            socket.close();
            console.log('socket disconnected');
            s1 = -1; s2 = -1; s3 = -1; s4 = -1; s5 = -1; s6 = -1; s7 = -1; s8 = -1; s9 = -1; s10 = -1; s11 = -1; s12 = -1; s13 = -1; s14 = -1; s15 = -1; s16 = -1; s17 = -1; s18 = -1; s19 = -1; s20 = -1; s21 = -1; s22 = -1; s23 = -1; s24 = -1;
        }
    }, [])


    useEffect(() => {

        if (!props.userid) return;
        axios.post('https://waveapi.in/api/client/clientssgetclickstake', {

            id: props.userid,
            token: props.utoken,

        })
            .then(result => {

                if (result.status === 210) {

                    setcoinFactor(parseInt(result.data[0].coin_type));
                    setdStake(result.data[0].defaultStake);
                    setSsstake1(result.data[0].SSstake1);
                    setSsstake2(result.data[0].SSstake2);
                    setSsstake3(result.data[0].SSstake3);
                    setSsstake4(result.data[0].SSstake4);
                    setSsstake5(result.data[0].SSstake5);
                    setSsstake6(result.data[0].SSstake6);
                    setSsstake7(result.data[0].SSstake7);
                    setSsstake8(result.data[0].SSstake8);

                }
            }


            ).catch(e => {

            });

        return () => {

        }
    }, [props.userid]);


    useEffect(() => {

        if (!eventid) {
            props.changeLiveStreaming(null);
            return;
        }
        axios.post("https://waveapi.in/api/client/clientlivestreaming", {
            eventid: eventid,
        })
            .then((result) => {
                if (result.status === 210) {
                    if (result.data[0].tvId && result.data[0].tvId != '') {
                        props.changeLiveStreaming(result.data[0].tvId);
                        console.log('tv link changed');
                    }
                }
            })
            .catch((e) => {
                //setIsError(true);
            });
    }, [eventid]);




    useEffect(() => {


        if (!eventid) return;

        axios.post('https://waveapi.in/api/client/clientgetFullMarket', {

            eventId: eventid,

        })
            .then(result => {
                if ((result.data.length > 0 && result.data[0].matchStatus != 1) || result.data.length === 0) {
                    matchEndedMarket = true;
                }


                if (result.data[0]) {
                    let obj = result.data[0];
                    setrunner1BackRate1(obj.runner1BackRate1);
                    setrunner1LayRate1(obj.runner1LayRate1);
                    setrunner2BackRate1(obj.runner2BackRate1);
                    setrunner2LayRate1(obj.runner2LayRate1);
                    if (obj.selectionIdTie) {
                        settieBackRate1(obj.tieBackRate1);
                        settieLayRate1(obj.tieLayRate1);
                    }

                    obj.marketStartTime = moment.tz(obj.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss')
                    setmarketData(obj);
                }

                if (newrefresh) {
                    setnewrefresh(false);
                }

            })
            .catch(e => {

            });

        axios.post('https://waveapi.in/api/client/clientgetBookMakerMarket', {

            eventId: eventid,

        })
            .then(result => {


                if (result.data.length === 0 || (result.data.length > 0 && result.data[0].matchStatus != 1)) {
                    matchEndedBook = true;
                }


                if (result.data[0]) {
                    let obj = result.data[0];
                    setbookData2(obj);
                  
                }

                if (newrefresh) {
                    setnewrefresh(false);
                }

            })
            .catch(e => {

            });
    }, []);

    if (matchEndedBook && matchEndedMarket) {
        window.location.href = '/';
    }

    useEffect(() => {

        socket.on(eventid, (val) => {

            var value = JSON.parse(val);

            if (value.messageType === 'betfair_match_rate') {
                setmarketBetStatus(value.status);

                if (value.status == '0' && liquidityStatus == 0) {
                    setliquidityStatus(1);

                }
                if (s1 !== value.runner1BackRate1) {
                    setrunner1BackRate1(value.runner1BackRate1);
                    s1 = value.runner1BackRate1;
                    c1 = 1;
                    setTimeout(() => { c1 = 0; }, 700);
                }


                if (s2 !== value.runner1BackSize1) {
                    setrunner1BackSize1(value.runner1BackSize1);
                    s2 = value.runner1BackSize1;
                }

                if (s3 !== value.runner1LayRate1) {
                    setrunner1LayRate1(value.runner1LayRate1);
                    s3 = value.runner1LayRate1;
                    c2 = 1;
                    setTimeout(() => { c2 = 0; }, 700);
                }

                if (s4 !== value.runner1LaySize1) {
                    setrunner1LaySize1(value.runner1LaySize1);
                    s4 = value.runner1LaySize1;
                }

                if (s5 !== value.runner2BackRate1) {
                    setrunner2BackRate1(value.runner2BackRate1);
                    s5 = value.runner2BackRate1;
                    c3 = 1;
                    setTimeout(() => { c3 = 0; }, 700);
                }

                if (s6 !== value.runner2BackSize1) {
                    setrunner2BackSize1(value.runner2BackSize1);
                    s6 = value.runner2BackSize1;
                }

                if (s7 !== value.runner2LayRate1) {
                    setrunner2LayRate1(value.runner2LayRate1);
                    s7 = value.runner2LayRate1;
                    c4 = 1;
                    setTimeout(() => { c4 = 0; }, 700);
                }

                if (s8 !== value.runner2LaySize1) {
                    setrunner2LaySize1(value.runner2LaySize1);
                    s8 = value.runner2LaySize1;

                }

                if (s9 !== value.tieBackRate1) {
                    settieBackRate1(value.tieBackRate1);
                    s9 = value.tieBackRate1;
                    c5 = 1;
                    setTimeout(() => { c5 = 0; }, 700);
                }
                if (s10 !== value.tieBackSize1) {
                    settieBackSize1(value.tieBackSize1);
                    s10 = value.tieBackSize1;
                }

                if (s11 !== value.tieLayRate1) {
                    settieLayRate1(value.tieLayRate1);
                    s11 = value.tieLayRate1;
                    c6 = 1;
                    setTimeout(() => { c6 = 0; }, 700);
                }

                if (s12 !== value.tieLaySize1) {
                    settieLaySize1(value.tieLaySize1);
                    s12 = value.tieLaySize1;
                }
            }

            if (value[0] && value[0].messageType === 'session_rate') {
                let temp = value;
                if (marketData.rateMode == '2') {
                    temp.sort(function (a, b) {

                        return moment(a.selectionId) - moment(b.selectionId);
                    });
                }
                setfancyData(temp);
             
            }

            if (value.messageType === 'bookmaker_match_rate') {

                let temp = { ...bookData };

                temp.gameStatus = value.status;
                temp.runner1Status = value.runner1Status;
                temp.runner2Status = value.runner2Status;
                temp.runner3Status = value.runner3Status;
                temp.runner1BackRate1 = value.runner1BackRate1;
                temp.runner1LayRate1 = value.runner1LayRate1;
                temp.runner2BackRate1 = value.runner2BackRate1;
                temp.runner2LayRate1 = value.runner2LayRate1;
                temp.tieBackRate1 = value.tieBackRate1;
                temp.tieLayRate1 = value.tieLayRate1;

                //console.log('book1',value.tieBackRate1,value.tieBackRate1);
                setbookData(temp);

            }

            if (value.messageType === 'match_score') {
                let temp = value;

                setScoreData(temp);

            }
        })


        return () => {
            socket.removeAllListeners(eventid);
            setrunner1BackRate1(' '); setrunner2BackRate1(' ');
            setrunner1LayRate1(' '); setrunner2LayRate1(' ');
            setrunner1BackSize1(' '); setrunner2BackSize1(' ');
            setrunner1LaySize1(' '); setrunner2LaySize1(' ');
            setfancyData([]);
        }
    }, [])

    const changeBook = (val1, val2, val3) => {

        if (val3 == '' || val3 == ' ') {
            return;
        }

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }
        setdefaultStake(dStake);
        if (val2 === 2) {

            setBookclick(true);
            setBookclick2(false);
            setBookclick3(false);
            setteamName('B');
        }
        else if (val2 === 1) {

            setBookclick2(true);
            setBookclick(false);
            setBookclick3(false);
            setteamName('A');
        }
        else if (val2 === 3) {

            setBookclick3(true);
            setBookclick2(false);
            setBookclick(false);
            setteamName('T');
        }
        setselectBack(val1);
        setbOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }

    }

    const changeMatchodds = (val1, val2, val3) => {

        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        }

        setdefaultStake(dStake);
        if (val2 === 2) {
            setMatchoddsclick(true);
            setMatchoddsclick2(false);
            setMatchoddsclick3(false);
            setteamName('B');

        }
        else if (val2 === 1) {
            setMatchoddsclick2(true);
            setMatchoddsclick(false);
            setMatchoddsclick3(false);
            setteamName('A');
        }
        else if (val2 === 3) {
            setMatchoddsclick3(true);
            setMatchoddsclick2(false);
            setMatchoddsclick(false);

            setteamName('T');
        }

        setselectMatchBack(val1);
        setmOdds(val3);

        if (val1 === 1 || val1 === 3 || val1 === 5) {
            setoddsType('LAGAI');
        }
        else if (val1 === 2 || val1 === 4 || val1 === 6) {
            setoddsType('KHAI');
        }



    }
    const closeMatchoddsBet = () => {

        setselectMatchBack(0);
        setMatchoddsclick2(false);
        setMatchoddsclick(false)
        setMatchoddsclick3(false);


    }
    const closeBookBet = () => {

        setselectBack(0);
        setBookclick2(false);
        setBookclick(false);
        setBookclick3(false);


    }

    const changeDefaultStake = (event) => {

        setdefaultStake(event.target.value);


    }

    useEffect(() => {
        //console.log(defaultStake);

        if (Matchoddsclick || Matchoddsclick2 || Matchoddsclick3) {

            var amount = defaultStake;
            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;


            if (amount === ' ' || amount === '') return;

            if (pnlMarket.teamA && pnlMarket.teamB && pnlMarket.tie && (pnlMarket.teamA != 0 || pnlMarket.teamB != 0 || pnlMarket.tie != 0)) {
                win_teamA = parseFloat(pnlMarket.teamA);
                win_teamB = parseFloat(pnlMarket.teamB);
                win_tie = parseFloat(pnlMarket.tie);

            }

            if (oddsType === 'LAGAI') {
                if (teamName === 'A') {
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamA = win_teamA + parseFloat(amount) * parseFloat(mOdds - 1);
                }
                else if (teamName === 'B') {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else {

                    win_teamA = win_teamA - parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount) * parseFloat(mOdds - 1);

                }
            }

            else {

                if (teamName === 'A') {

                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamA = win_teamA - parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else if (teamName === 'B') {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_tie = win_tie + parseFloat(amount);
                    win_teamB = win_teamB - parseFloat(amount) * parseFloat(mOdds - 1);

                }
                else {

                    win_teamA = win_teamA + parseFloat(amount);
                    win_teamB = win_teamB + parseFloat(amount);
                    win_tie = win_tie - parseFloat(amount) * parseFloat(mOdds - 1);


                }

            }

            var obj = {};
            obj.teamA = win_teamA.toFixed(2);
            obj.teamB = win_teamB.toFixed(2);
            obj.tie = win_tie.toFixed(2);


            setpnlMarket2(obj);



        }

        else if (Bookclick || Bookclick2 || Bookclick3) {

            var win_teamA = 0;
            var win_teamB = 0;
            var win_tie = 0;

            if (defaultStake === ' ' || defaultStake === '') return;

            if (pnlBook.teamA && pnlBook.teamB && pnlBook.tie && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0)) {
                win_teamA = parseFloat(pnlBook.teamA);
                win_teamB = parseFloat(pnlBook.teamB);
                win_tie = parseFloat(pnlBook.tie);

            }

            if (oddsType == 'LAGAI') {
                if (teamName == 'A') {
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamA = win_teamA + parseFloat(defaultStake) * parseFloat(bOdds / 100);
                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA - parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
            }

            else {

                if (teamName == 'A') {

                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamA = win_teamA - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else if (teamName == 'B') {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_tie = win_tie + parseFloat(defaultStake);
                    win_teamB = win_teamB - parseFloat(defaultStake) * parseFloat(bOdds / 100);

                }
                else {

                    win_teamA = win_teamA + parseFloat(defaultStake);
                    win_teamB = win_teamB + parseFloat(defaultStake);
                    win_tie = win_tie - parseFloat(defaultStake) * parseFloat(bOdds / 100);


                }

            }

            var obj = {};
            obj.teamA = win_teamA.toFixed(2);
            obj.teamB = win_teamB.toFixed(2);
            obj.tie = win_tie.toFixed(2);

            setpnlBook2(obj);
        }

    }, [defaultStake])





    const handlebookpin = () => {
        setbookpin(!bookpin)
    }
    const handlefancypin = () => {
        setFancypin(!Fancypin)
    }

    const handlelivempin = () => {
        setlivemarpin(!livemarpin)
    }

    const OpenfancyBet = (index) => {
        setdefaultStake(dStake);
        setclick(index);
        setselectFancy(1)
        setfield(1);

    }

    const OpenfancyBetLay = (index) => {
        setdefaultStake(dStake);
        setclick(index);
        setselectFancyLay(1);
        setfield(2);

    }
    const closeFancyBet = () => {
        setclick(-1);
        setselectFancy(0);
        setselectFancyLay(0);
    }

    const handleMaxStake = () => {
        setMaxstake(true)
    }

    const handleMaxStakeclose = () => {
        setMaxstake(false)
    }

    const handleFancyBook = (val) => {
        setshowFancyBook(val);
    }


    const handleRefresh = () => {

        const utoken = cookies.get('token');
        const user = cookies.get('id');
        axios.post('https://waveapi.in/api/client/clientrefresh', {
            id: user,
            token: utoken
        })
            .then(result => {
                if (result.status === 210) {

                    props.changeBalance(result.data[0].val - Math.abs(result.data[1].val));
                    if (result.data[1].val) {

                        props.changeExposure(Math.abs(result.data[1].val));
                    }
                    else {
                        props.changeExposure(0);
                    }
                }

                if (result.status === 220) {
                    console.log('refresh unsucessful');

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });
    };

    const plMarket = () => {

        if (!props.userid) return;
        axios.post('https://waveapi.in/api/client/clientplmarket', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: marketData.marketId

        })
            .then(result => {
                if (result.status === 210) {

                    let obj = result.data;
                    setpnlMarket(obj);


                }

                if (result.status === 220) {
                    console.log('refresh unsucessful');

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });
    }



    const plBook = () => {
        if (!props.userid) return;
        axios.post('https://waveapi.in/api/client/clientplmarket', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: bookData2.marketId
        })
            .then(result => {
                if (result.status === 210) {

                    let obj = result.data;
                    setpnlBook(obj);


                }

                if (result.status === 220) {
                    console.log('refresh unsucessful');

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });

    }



    const plFancy = () => {
        if (!props.userid || !eventid) return;
        axios.post('https://waveapi.in/api/client/clientplfancy', {
            id: props.userid,
            token: props.utoken,
            eventId: eventid,
        })
            .then(result => {
                if (result.status === 210) {

                    let obj = {};
                    result.data.map((item) => {
                        obj[item.marketId] = item.exposure;
                    });
                    setpnlFancy(obj);


                }

                if (result.status === 220) {
                    console.log('fancy Pnl unsucessful');

                }
            }

            ).catch(e => {
                //  setIsError(true);
            });
    }



    useEffect(() => {

        if (marketData.matchStatus == 1) plMarket();
        if (bookData2.matchStatus == 1) plBook();

        return () => {

            setpnlMarket({});
            setpnlBook({});

        }
    }, [props.userid, bookData2.marketId, marketData.marketId])




    useEffect(() => {
        plFancy();

    }, [props.userid, eventid])


    const fancyBet = (selectionId, fld, runnerName) => {

        if (!props.userid) {
            return;
        }
        var dupBet = cookies.get('dupBet');
        var oddT;
        if (fld === 1) {
            oddT = 'NO';
        }
        else if (fld === 2) {
            oddT = 'YES';
        }

        axios.post('https://waveapi.in/api/placebet/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: selectionId, //fancy selection id
            odds: fOdds,                  //run 
            oddsType: oddT,           //YES NO
            betType: 'fancy',            //fancy
            coin: defaultStake,
            teamName: fsize,          //rate 110,100
            dupbet: dupBet,
            anyOdds: acceptAnyOdds

        }, { withCredentials: true }
        )
            .then(result => {
                props.handleRefreshhh(3000, 'Placing bet...', 2);
                if (result.data == 6) {
                    props.changeMsgNumber(1);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);

                    props.changeBetInfo({ oddstype: oddT, runner: runnerName, amount: defaultStake, rate: fOdds + '/' + fsize });
                    handleRefresh();
                    plFancy();
                    setupdateBet(!updateBet);
                }

                else if (result.data == 1) {
                    props.changeMsgNumber(3);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }

                else if (result.data == 2) {
                    props.changeMsgNumber(4);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 3) {
                    props.changeMsgNumber(5);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 4) {
                    props.changeMsgNumber(6);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 5) {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 7) {
                    props.changeMsgNumber(8);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 123) {
                    props.changeMsgNumber(9);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 13) {
                    props.changeMsgNumber(10);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data.charAt(0) == 8) {
                    props.changeMsgNumber(11);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else if (result.data.charAt(0) == 9) {
                    props.changeMsgNumber(12);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }



            }

            ).catch(e => {
              

            });

    };




    const bookBet = () => {

        if (!props.userid) {
            return;
        }

        var dupBet = cookies.get('dupBet');
        //console.log(Math.round(((bOdds/100) + 1 + Number.EPSILON) * 100) / 100);

        axios.post('https://waveapi.in/api/placebet/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: bookData2.marketId,
            odds: parseFloat(bOdds / 100 + 1).toFixed(2).toString(),
            oddsType: oddsType,
            betType: 'match',
            coin: defaultStake,
            teamName: teamName,
            dupbet: dupBet,
            anyOdds: acceptAnyOdds

        }, { withCredentials: true }
        )
            .then(result => {
                props.handleRefreshhh(3000, 'Placing bet...', 2);
                if (result.data == 6) {
                    props.changeMsgNumber(1);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);

                    props.changeBetInfo({ oddstype: oddsType === 'KHAI' ? 'LAY' : 'BACK', runner: teamName === 'A' ? bookData2.runnerName1 : bookData2.runnerName2, amount: defaultStake, rate: bOdds });
                    handleRefresh();
                    plBook();
                    setupdateBet(!updateBet);
                }

                else if (result.data == 1) {
                    props.changeMsgNumber(3);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }

                else if (result.data == 2) {
                    props.changeMsgNumber(4);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 3) {
                    props.changeMsgNumber(5);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 4) {
                    props.changeMsgNumber(6);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 5) {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 7) {
                    props.changeMsgNumber(8);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 123) {
                    props.changeMsgNumber(9);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 13) {
                    props.changeMsgNumber(10);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data.charAt(0) == 8) {
                    props.changeMsgNumber(11);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else if (result.data.charAt(0) == 9) {
                    props.changeMsgNumber(12);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);

                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }


            }

            ).catch(e => {
                console.log(e);

            });

    };



    const marketBet = () => {

        if (!props.userid) {
            return;
        }
        var dupBet = cookies.get('dupBet');

        axios.post('https://waveapi.in/api/placebet/placebets', {

            clientId: props.userid,
            token: props.utoken,
            eventId: eventid,
            marketId: marketData.marketId,
            odds: mOdds,
            oddsType: oddsType,
            betType: 'match',
            coin: defaultStake,
            teamName: teamName,
            dupbet: dupBet


        }, { withCredentials: true }
        )
            .then(result => {
                props.handleRefreshhh(3000, 'Placing bet...', 2);
                if (result.data == 6) {
                    props.changeMsgNumber(1);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);

                    props.changeBetInfo({ oddstype: oddsType === 'KHAI' ? 'LAY' : 'BACK', runner: teamName === 'A' ? marketData.runnerName1 : marketData.runnerName2, amount: defaultStake, rate: mOdds });
                    handleRefresh();
                    plMarket();
                    setupdateBet(!updateBet);
                }
                else if (result.data == 1) {
                    props.changeMsgNumber(3);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }

                else if (result.data == 2) {
                    props.changeMsgNumber(4);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 3) {
                    props.changeMsgNumber(5);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 4) {
                    props.changeMsgNumber(6);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 5) {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 7) {
                    props.changeMsgNumber(8);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 123) {
                    props.changeMsgNumber(9);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data == 13) {
                    props.changeMsgNumber(10);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
                else if (result.data.charAt(0) == '8') {
                    props.changeMsgNumber(11);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else if (result.data.charAt(0) == 9) {
                    props.changeMsgNumber(12);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                    props.changeStakeInfo({ minStake: result.data.split("_")[1], maxStake: result.data.split("_")[1] });
                }
                else {
                    props.changeMsgNumber(7);
                    setTimeout(() => {
                        props.changeMsgNumber(0);
                    }, 1500);
                }
            }

            ).catch(e => {
                console.log(e);

            });
    };


    const marketBetClicked = () => {
        setshowConfirmBet(true);
    }

    const closeConfirmBet = () => {
        setshowConfirmBet(false);
    }

    return (

        <React.Fragment>

            {props.refreshhh === true &&
                <Loader />}

            <div class="container matchList-matchBox-outer matchBox-outer" style={{ padding: '0px' }}>
                {eventT == 4 && <CricketScore eventId={eventid} ScoreData={ScoreData} marketData={marketData} />}
            </div>
            <div class="container matchList-matchBox-outer matchBox-outer" style={{ padding: '0px' }}>
                <div className="bet-place-tbl-th market_type_row market-name-row-color" style={{ lineHeight: '30px' }}>
                    <span style={{ marginLeft: '5px' }}>Market : Min : {parseInt(marketData.minStack) * coinFactor} | Max : {parseInt(marketData.maxStack) * coinFactor}</span>
                    <img src={TV} onClick={() => { props.ChangeSetTv(!props.Tvopen) }} style={{ cursor: "pointer" }} /></div></div>

            {/* https://live-tv2.jmk888.com/ */}

            {props.Tvopen === false &&
                props.Tab === 0 &&
                props.isLoggedIn === true &&
                props.liveStream && props.liveStream != '' &&
                <div class="container matchList-matchBox-outer matchBox-outer" style={{ padding: '0px' }}>

                    <iframe _ngcontent-lbo-c120="" height="100%" overflow="hidden" width="100%" id="video-canvas" class="ng-tns-c120-0" src={`https://orangeexch999.com/jmp/nm3/all.php?eventId=${eventid}`} />
                    {/* <TVStremaing Tvopen={props.Tvopen} isShown={props.isShown} ChangeSetTv={props.ChangeSetTv} liveStream={props.liveStream} TvControl={props.TvControl} /> */}
                    {/* {`http://143.110.251.253/#/score2/${props.eventId}`} */}

                </div>
            }

            {bookData2.matchStatus == 1 &&
                <div class="container matchList-matchBox-outer matchBox-outer" style={{ padding: 0 }}>
                    <div class="content-top-padding overall_page_streach">
                        <div>
                            <div style={{ height: '35px', width: '100%', paddingTop: '7px', textAlign: 'center', fontSize: '15px', color: 'white', backgroundColor: '#c96e6e' }}>
                                Market : Match Winner</div>
                            <div style={{ backgroundColor: 'whitesmoke' }}>
                                <table style={{ height: '40px', width: '100%', border: '5px solid whitesmoke' }}>
                                    <tbody>
                                        <tr>
                                            <th class="tableheader" style={{ backgroundColor: '#49494A', color: 'white', width: '42.7%' }}>RUNNER</th>
                                            <th class="tableheader" style={{ backgroundColor: 'whitesmoke', color: '#49494A', width: '20%' }}>LAGAI</th>
                                            <th class="tableheader" style={{ backgroundColor: 'whitesmoke', color: '#49494A', width: '20%' }}>KHAI</th>
                                            <th class="tableheader" style={{ backgroundColor: '#49494A', color: 'white', width: '30%' }}>POSITION</th>
                                        </tr>
                                        <tr>
                                            <td class="tableheader" style={{ backgroundColor: 'rgb(255 255 255)', color: '#374049', width: '30%' }}>{bookData2.runnerName1}</td>
                                            <td class="tableheader" onClick={() => changeBook(1, 1, bookData.runner1BackRate1)} class="tableheader" style={{ backgroundColor: '#a7d8fd', width: '20%', fontSize: '16px' }}><a style={{ color: '#000' }} onClick={() => { setkhaeelagee('LAGAI'); setruner12(bookData2.runnerName1) }} className={`back-1 ${(selectMatchBack === 1) ? "select" : "null"}`} fullmarketodds="2.22">{bookData.runner1BackRate1}</a> </td>
                                            <td class="tableheader" onClick={() => changeBook(2, 1, bookData.runner1LayRate1)} style={{ backgroundColor: '#f6b771', width: '20%', fontSize: '16px' }}><a onClick={() => { setkhaeelagee("KHAI"); setruner12(bookData2.runnerName1) }} fullmarketodds="2.24">{bookData.runner1LayRate1}</a></td>
                                            <td class="tableheader" style={{ backgroundColor: 'rgb(255 255 255)', color: '#374049', width: '30%' }}>
                                                {pnlBook.teamA && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && !isNaN(Math.abs(pnlBook.teamA)) &&
                                                    <span id="before" className={`${pnlBook.teamA > 0 ? "win" : "lose"}`} > {pnlBook.teamA > 0 ? '+' + pnlBook.teamA : '-' + Math.abs(pnlBook.teamA)}</span>}

                                                {(Bookclick2 || Bookclick || Bookclick3) && defaultStake != ' ' && defaultStake != '' && !isNaN(Math.abs(pnlBook2.teamA)) &&
                                                    <span id="after" className={`${pnlBook2.teamA > 0 ? "to-win" : "to-lose"}`} >
                                                        {pnlBook2.teamA > 0 ? '+' + pnlBook2.teamA : '-' + Math.abs(pnlBook2.teamA)}</span>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {(Bookclick2 === true && !(bookData.gameStatus != 2 || bookData.runner1Status == 2)) &&
                                    <div>
                                        <div className="modal fade in" id="myModal" role="dialog" style={{ display: 'block' }}>
                                            <div className="modal-dialog modalbet" style={{ width: '100%' }}>
                                                <div className="modal-content">
                                                    <div className="modal-body" style={{ padding: 0 }}>
                                                        <div className="innerloader" style={{ display: 'none' }} />
                                                        <div className="table table-responsive" style={{ margin: 0 }}>
                                                            <table style={{ width: '100%', fontFamily: 'Arial, Helvetica, sans-serif !important' }} className="market-rate-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="cls-td" style={{ color: '#000', textAlign: 'center' }} id="macthoddsbetslipmatchname">{runer12}</td>
                                                                        <td className="cls-td" style={{ color: '#000', textAlign: 'center' }} id="modal_type">{khaeelagee}</td>
                                                                        <td className="cls-td" style={{ color: '#000', textAlign: 'center' }} id="macthoddsbetslipodds">{bOdds} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ padding: 0 }}><input style={{ color: '#223f6f', backgroundColor: '#e9e9e9', fontSize: '16px', border: 'none', textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 'bold' }} type="text" id="stakeinputmarchodds" className="form-control" placeholder=" Enter Coins" defaultValue={defaultStake} /></td>
                                                                        <td className="close-num-keyboard" id="close-numeric-keyboard" style={{ textAlign: 'center', color: '#fff', backgroundColor: '#1fabb5' }} onClick={() => { closeBookBet(); setdefaultStake(dStake); }}>
                                                                            <button style={{ border: '0px', background: '#0000', color: '#fff', paddingTop: '5px' }} type="button" className data-dismiss="modal"><i className="fa fa-times-circle" style={{ fontSize: '28px', textAlign: 'center' }} /></button></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="table table-responsive" style={{ margin: 0 }}>
                                                            <table style={{}} className="num-pad min-max-stack-table">
                                                                <tbody>
                                                                    <tr className="bet-values-row">
                                                                        <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('1000')}>1H</td>
                                                                        <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('5000')}>5H</td>
                                                                        <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('10000')}>10H</td>
                                                                        <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('25000')}>25H</td>
                                                                        <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('50000')}>50H</td>
                                                                    </tr>
                                                                    <tr className="bet-values-row">
                                                                        <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('100000')}>1L</td>
                                                                        <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('200000')}>2L</td>
                                                                        <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('500000')}>5L</td>
                                                                        <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('1000000')}>10L</td>
                                                                        <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('2500000')}>25L</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="table table-responsive" style={{ margin: 0 }}>
                                                            <table style={{ width: '100%', fontSize: '80px' }} className="num-pad numpad-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }}>1</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }}>2</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }}>3</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }}>4</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }}>5</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }}>6</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }}>7</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }}>8</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }}>9</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }}>c</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }}>0</td>
                                                                        <td className="cls-td text-center" id="clear" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }}><i className="fa fa-times" aria-hidden="true" /></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="table table-responsive" style={{ margin: 0 }}>
                                                            <table style={{ width: '100%', fontFamily: 'Arial, Helvetica, sans-serif !important' }} className="table table-responsive">
                                                                <tbody>
                                                                    <tr>
                                                                        <th style={{ background: '#1fabb5', lineHeight: '30px', textAlign: 'center' }} onClick={() => { closeBookBet(); setdefaultStake(dStake); }}><button style={{ border: '0px', background: '#0000', color: '#fff' }} type="button" className data-dismiss="modal">CANCEL</button></th>
                                                                        <th style={{ background: '#c96e6d', lineHeight: '30px', textAlign: 'center' }} onClick={(e) => { e.preventDefault(); bookBet(); closeBookBet(); props.handleRefreshhh(5000, 'Placing bet...', 1); setdefaultStake(dStake); }} ><button style={{ border: '0px', background: '#0000', color: '#fff' }} type="button" id="placeFancyBet" className="btn-round-done"><i className="fa fa-circle-o-notch fa-spin innerloader" style={{ display: 'none', fontSize: '24px', position: 'absolute', lineHeight: '30px' }} />DONE</button></th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    //   <div>
                                    //         <div style={{ height: '250px', width: '100%', backgroundColor: '#c96e6d', border: '1px solid #c96e6d' }}>
                                    //             <div style={{ height: '70px', width: '100%' }}>
                                    //                 <div style={{ height: '20px', width: '100%', padding: '5px', paddingRight: '70px', fontSize: '12px', textAlign: 'right' }}>Min Bet: {parseInt(bookData2.minStack) * coinFactor}</div>
                                    //                 <div style={{ height: '50px', width: '100%', backgroundColor: '#c96e6d' }}>

                                    //                     <div style={{ height: '34px', width: '40%', backgroundColor: 'whitesmoke', float: 'left', borderRadius: '20px', border: '1px solid gray', margin: '3px 5px' }}>
                                    //                         <div id="inputOdds" class="input-num">
                                    //                             <div style={{ height: '33px', width: '25%', float: 'left' }}>

                                    //                             </div>
                                    //                             <div id="odds" class="typed" style={{ height: '32px', width: '50%', float: 'left', backgroundColor: 'whitesmoke' }}>
                                    //                                 <b>{bOdds}</b>
                                    //                             </div>
                                    //                             <div style={{ height: '33px', width: '25%', float: 'left' }}>

                                    //                             </div>
                                    //                         </div>
                                    //                     </div>

                                    //                     <div style={{ height: '34px', width: '40%', backgroundColor: 'whitesmoke', float: 'right', borderRadius: '20px', border: '1px solid gray', margin: '3px 5px' }}>
                                    //                         <div id="inputOdds" class="input-num" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake}>
                                    //                             <div style={{ height: '33px', width: '25%', float: 'left', borderRight: '1px solid gray' }}>
                                    //                                 <a id="oddsDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(bookData2.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} >
                                    //                                     <img style={{ height: '25px', width: '20px', paddingTop: '10px' }} src={iconminus} />
                                    //                                 </a>
                                    //                             </div>
                                    //                             <div id="odds" class="typed" style={{ height: '32px', width: '50%', float: 'left', backgroundColor: 'whitesmoke' }} autoFocus>
                                    //                                 <b>{defaultStake}</b>
                                    //                             </div>

                                    //                             <div style={{ height: '33px', width: '25%', float: 'left', borderLeft: '1px solid gray' }}>
                                    //                                 <a id="oddsUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(bookData2.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }}>
                                    //                                     <img style={{ height: '25px', width: '20px', paddingTop: '10px' }} src={iconplus} />
                                    //                                 </a>
                                    //                             </div>
                                    //                         </div>
                                    //                     </div>
                                    //                 </div>
                                    //             </div>
                                    //             <div style={{ height: '37px', width: '100%', backgroundColor: 'rgb(116 141 160)', borderRadius: '20px' }}>
                                    //                 <li class="betvalue_li" onClick={() => setdefaultStake(ssstake1)}>{ssstake1}</li>
                                    //                 <li class="betvalue_li" onClick={() => setdefaultStake(ssstake2)}>{ssstake2}</li>
                                    //                 <li class="betvalue_li" onClick={() => setdefaultStake(ssstake3)}>{ssstake3}</li>
                                    //                 <li class="betvalue_li" onClick={() => setdefaultStake(ssstake4)}>{ssstake4}</li>
                                    //                 <li class="betvalue_li" onClick={() => setdefaultStake(ssstake3)}>{ssstake5}</li>
                                    //                 <li class="betvalue_li" onClick={() => setdefaultStake(ssstake4)}>{ssstake6}</li>
                                    //                 <li class="betvalue_li" onClick={() => setdefaultStake(ssstake5)}>{ssstake7}</li>
                                    //                 <li class="betvalue_li" onClick={() => setdefaultStake(ssstake6)} style={{ borderRight: 'none' }}>{ssstake8}</li>

                                    //             </div>

                                    //             <div style={{ height: '8px' }}></div>
                                    //             <div style={{ height: '80px', width: '100%', border: '1px solid gray', borderRadius: '20px', backgroundColor: 'whitesmoke' }}>
                                    //                 <div style={{ height: '80px', width: '85%', float: 'left' }}>
                                    //                     <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray', borderTopLeftRadius: '7px ' }}>1</li>
                                    //                     <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>2</li>
                                    //                     <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>3</li>
                                    //                     <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>4</li>
                                    //                     <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>5</li>
                                    //                     <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>6</li>
                                    //                     <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} style={{ borderRight: '1px solid gray', borderBottomLeftRadius: '7px', borderBottom: '1px solid gray' }}>7</li>
                                    //                     <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>8</li>
                                    //                     <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>9</li>
                                    //                     <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>0</li>
                                    //                     <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>00</li>
                                    //                     <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '.') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>.</li>

                                    //                 </div>
                                    //                 <div style={{ height: '78px', width: '15%', float: 'left', }} onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }}>
                                    //                     {/* <div style={{ height: '50px', width: '50px',borderRadius:'50%',border: '1px solid gray', justifyContent:'center', alignItems:'center',textAlign:'center', marginTop:'15px', paddingTop:'14px'}} >Clear</div> */}
                                    //                     <div style={{ height: '50px', width: '50px', borderRadius: '50%', border: '1px solid gray', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '15px', paddingTop: '14px' }} >Clear</div>

                                    //                 </div>
                                    //             </div>
                                    //             <div style={{ height: '50px', width: '100%' }}>
                                    //                 <div style={{ height: '50px', width: '50%', float: 'left' }}>
                                    //                     <div onClick={() => { closeBookBet(); setdefaultStake(dStake); }} class=" betslipcancel_btn"><b>Cancel</b></div>
                                    //                 </div>
                                    //                 <div style={{ height: '50px', width: '50%', float: 'left' }}>
                                    //                     <div class=" betslipPlacebet_btn" onClick={(e) => { e.preventDefault(); bookBet(); closeBookBet(); props.handleRefreshhh(5000, 'Placing bet...', 1); setdefaultStake(dStake); }}><b>Place Bet</b></div>
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                    //     </div>

                                }

                                <table style={{ height: '40px', width: '100%', border: '5px solid whitesmoke' }}>
                                    <tbody>
                                        <tr>
                                            <td class="tableheader" style={{ backgroundColor: 'rgb(255 255 255)', color: '#374049', width: '42.7%' }}>{marketData.runnerName2}</td>
                                            <td class="tableheader" onClick={() => changeBook(3, 2, bookData.runner2BackRate1)}
                                                style={{ backgroundColor: '#a7d8fd', width: '20%', fontSize: '16px' }}>
                                                <a style={{ color: '#000' }}
                                                    fullmarketodds="1.81">{bookData.runner2BackRate1}</a>
                                            </td>
                                            <td class="tableheader" onClick={() => changeBook(4, 2, bookData.runner2LayRate1)}
                                                style={{ backgroundColor: '#f6b771', width: '20%', fontSize: '16px' }}>
                                                <a style={{ color: '#000' }}
                                                    fullmarketodds="1.82">{bookData.runner2LayRate1}</a>
                                            </td>

                                            <td class="tableheader" style={{ backgroundColor: 'rgb(255 255 255)', color: '#374049', width: '30%' }}>
                                                {pnlBook.teamB && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && !isNaN(Math.abs(pnlBook.teamB)) &&
                                                    <span id="before" className={`${pnlBook.teamB > 0 ? "win" : "lose"}`} >
                                                        {pnlBook.teamB > 0 ? '+' + pnlBook.teamB : '−' + Math.abs(pnlBook.teamB)}</span>}

                                                {(Bookclick2 || Bookclick || Bookclick3) && defaultStake != ' ' && defaultStake != '' && !isNaN(Math.abs(pnlBook2.teamB)) &&
                                                    <span id="after" className={`${pnlBook2.teamB > 0 ? "to-win" : "to-lose"}`} >
                                                        {pnlBook2.teamB > 0 ? '+' + pnlBook2.teamB : '−' + Math.abs(pnlBook2.teamB)}</span>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {(Bookclick === true && !(bookData.gameStatus != 2 || bookData.runner2Status == 2)) &&
                                    <div>

                                        <div className="modal fade in" id="myModal" role="dialog" style={{ display: 'block' }}>
                                            <div className="modal-dialog modalbet" style={{ width: '100%' }}>
                                                <div className="modal-content">
                                                    <div className="modal-body" style={{ padding: 0 }}>
                                                        <div className="innerloader" style={{ display: 'none' }} />
                                                        <div className="table table-responsive" style={{ margin: 0 }}>
                                                            <table style={{ width: '100%', fontFamily: 'sans-serif' }} className="market-rate-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="cls-td" style={{ color: '#000', textAlign: 'center', fontFamily: 'sans-serif' }} id="macthoddsbetslipmatchname"></td>
                                                                        <td className="cls-td" style={{ color: '#000', textAlign: 'center' }} id="modal_type">{yesnoval}</td>
                                                                        <td className="cls-td" style={{ color: '#000', textAlign: 'center' }} id="macthoddsbetslipodds">{fOdds} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ padding: 0 }}><input style={{ color: '#223f6f', backgroundColor: '#e9e9e9', fontSize: '16px', border: 'none', textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 'bold' }} type="text" id="stakeinputmarchodds" className="form-control" placeholder=" Enter Coins" defaultValue={defaultStake} /></td>
                                                                        <td className="close-num-keyboard" id="close-numeric-keyboard" style={{ textAlign: 'center', color: '#fff', backgroundColor: '#1fabb5' }} onClick={() => { closeBookBet(); setdefaultStake(dStake); }}><button style={{ border: '0px', background: '#0000', color: '#fff', paddingTop: '5px' }} type="button" className data-dismiss="modal"><i className="fa fa-times-circle" style={{ fontSize: '28px', textAlign: 'center' }} /></button></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="table table-responsive" style={{ margin: 0 }}>
                                                            <table style={{}} className="num-pad min-max-stack-table">
                                                                <tbody>
                                                                    <tr className="bet-values-row">
                                                                        <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('1000')}>1H</td>
                                                                        <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('5000')}>5H</td>
                                                                        <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('10000')}>10H</td>
                                                                        <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('25000')}>25H</td>
                                                                        <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('50000')}>50H</td>
                                                                    </tr>
                                                                    <tr className="bet-values-row">
                                                                        <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('100000')}>1L</td>
                                                                        <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('200000')}>2L</td>
                                                                        <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('500000')}>5L</td>
                                                                        <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('1000000')}>10L</td>
                                                                        <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('2500000')}>25L</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="table table-responsive" style={{ margin: 0 }}>
                                                            <table style={{ width: '100%', fontSize: '80px' }} className="num-pad numpad-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }}>1</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }}>2</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }}>3</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }}>4</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }}>5</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }}>6</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }}>7</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }}>8</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }}>9</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }}>c</td>
                                                                        <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }}>0</td>
                                                                        <td className="cls-td text-center" id="clear" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }}><i className="fa fa-times" aria-hidden="true" /></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="table table-responsive" style={{ margin: 0 }}>
                                                            <table style={{ width: '100%', fontFamily: 'Arial, Helvetica, sans-serif !important' }} className="table table-responsive">
                                                                <tbody>
                                                                    <tr>
                                                                        <th style={{ background: '#1fabb5', lineHeight: '30px', textAlign: 'center' }} onClick={() => { closeBookBet(); setdefaultStake(dStake); }}><button style={{ border: '0px', background: '#0000', color: '#fff' }} type="button" className data-dismiss="modal">CANCEL</button></th>
                                                                        <th style={{ background: '#c96e6d', lineHeight: '30px', textAlign: 'center' }} onClick={(e) => { e.preventDefault(); bookBet(); closeBookBet(); props.handleRefreshhh(5000, 'Placing bet...', 1); setdefaultStake(dStake); }} ><button style={{ border: '0px', background: '#0000', color: '#fff' }} type="button" id="placeFancyBet" className="btn-round-done"><i className="fa fa-circle-o-notch fa-spin innerloader" style={{ display: 'none', fontSize: '24px', position: 'absolute', lineHeight: '30px' }} />DONE</button></th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* <div style={{ height: '250px', width: '100%', backgroundColor: '#c96e6d', border: '1px solid #c96e6d' }}>
                                            <div style={{ height: '70px', width: '100%' }}>
                                                <div style={{ height: '20px', width: '100%', padding: '5px', paddingRight: '70px', fontSize: '12px', textAlign: 'right' }}>Min Bet: {parseInt(marketData.minStack) * coinFactor}</div>
                                                <div style={{ height: '50px', width: '100%', backgroundColor: '#c96e6d' }}>

                                                    <div style={{ height: '34px', width: '40%', backgroundColor: 'whitesmoke', float: 'left', borderRadius: '20px', border: '1px solid gray', margin: '3px 5px' }}>
                                                        <div id="inputOdds" class="input-num">
                                                            <div style={{ height: '33px', width: '25%', float: 'left' }}>

                                                            </div>
                                                            <div id="odds" class="typed" style={{ height: '32px', width: '50%', float: 'left', backgroundColor: 'whitesmoke' }}>
                                                                <b>{bOdds}</b>
                                                            </div>
                                                            <div style={{ height: '33px', width: '25%', float: 'left' }}>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ height: '34px', width: '40%', backgroundColor: 'whitesmoke', float: 'right', borderRadius: '20px', border: '1px solid gray', margin: '3px 5px' }}>
                                                        <div id="inputOdds" class="input-num" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake}>
                                                            <div style={{ height: '33px', width: '25%', float: 'left', borderRight: '1px solid gray' }}>
                                                                <a id="oddsDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(bookData2.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} >
                                                                    <img style={{ height: '25px', width: '20px', paddingTop: '10px' }} src={iconminus} />
                                                                </a>
                                                            </div>
                                                            <div id="odds" class="typed" style={{ height: '32px', width: '50%', float: 'left', backgroundColor: 'whitesmoke' }} autoFocus>
                                                                <b>{defaultStake}</b>
                                                            </div>

                                                            <div style={{ height: '33px', width: '25%', float: 'left', borderLeft: '1px solid gray' }}>
                                                                <a id="oddsUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(bookData2.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }}>
                                                                    <img style={{ height: '25px', width: '20px', paddingTop: '10px' }} src={iconplus} />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ height: '37px', width: '100%', backgroundColor: 'rgb(116 141 160)', borderRadius: '20px' }}>
                                                <li class="betvalue_li" onClick={() => setdefaultStake(ssstake1)}>{ssstake1}</li>
                                                <li class="betvalue_li" onClick={() => setdefaultStake(ssstake2)}>{ssstake2}</li>
                                                <li class="betvalue_li" onClick={() => setdefaultStake(ssstake3)}>{ssstake3}</li>
                                                <li class="betvalue_li" onClick={() => setdefaultStake(ssstake4)}>{ssstake4}</li>
                                                <li class="betvalue_li" onClick={() => setdefaultStake(ssstake3)}>{ssstake5}</li>
                                                <li class="betvalue_li" onClick={() => setdefaultStake(ssstake4)}>{ssstake6}</li>
                                                <li class="betvalue_li" onClick={() => setdefaultStake(ssstake5)}>{ssstake7}</li>
                                                <li class="betvalue_li" onClick={() => setdefaultStake(ssstake6)} style={{ borderRight: 'none' }}>{ssstake8}</li>                                                </div>
                                            <div style={{ height: '8px' }}></div>
                                            <div style={{ height: '80px', width: '100%', border: '1px solid gray', borderRadius: '20px', backgroundColor: 'whitesmoke' }}>
                                                <div style={{ height: '80px', width: '85%', float: 'left' }}>
                                                    <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray', borderTopLeftRadius: '7px ' }}>1</li>
                                                    <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>2</li>
                                                    <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>3</li>
                                                    <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>4</li>
                                                    <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>5</li>
                                                    <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>6</li>
                                                    <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} style={{ borderRight: '1px solid gray', borderBottomLeftRadius: '7px', borderBottom: '1px solid gray' }}>7</li>
                                                    <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>8</li>
                                                    <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>9</li>
                                                    <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>0</li>
                                                    <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>00</li>
                                                    <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '.') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>.</li>

                                                </div>
                                                <div style={{ height: '78px', width: '15%', float: 'left', }} onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }}>
                                                    <div style={{ height: '50px', width: '50px', borderRadius: '50%', border: '1px solid gray', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '15px', paddingTop: '14px' }} >Clear</div>
                                                </div>
                                            </div>
                                            <div style={{ height: '50px', width: '100%' }}>
                                                <div style={{ height: '50px', width: '50%', float: 'left' }}>
                                                    <div onClick={() => { closeMatchoddsBet(); setdefaultStake(dStake); }} class=" betslipcancel_btn"><b>Cancel</b></div>
                                                </div>
                                                <div style={{ height: '50px', width: '50%', float: 'left' }}>
                                                    <div class=" betslipPlacebet_btn" onClick={(e) => { e.preventDefault(); bookBet(); closeBookBet(); props.handleRefreshhh(5000, 'Placing bet...', 1); setdefaultStake(dStake); }}><b>Place Bet</b></div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                }
                                {marketData.runnerId3 && marketData.runnerId3 != ' ' &&

                                    <React.Fragment>

                                        <table style={{ height: '40px', width: '100%', border: '5px solid whitesmoke' }}>
                                            <tbody>
                                                <tr>
                                                    <td class="tableheader" style={{ backgroundColor: 'white', color: '#374049', width: '42.7%' }}>Draw</td>
                                                    <td class="tableheader" onClick={() => changeBook(5, 3, bookData.tieBackRate1)}
                                                        style={{ backgroundColor: '#a7d8fd', width: '20%', fontSize: '16px' }}>
                                                        <span
                                                            fullmarketodds="1.81">{bookData.tieBackRate1}</span>
                                                    </td>
                                                    <td class="tableheader" onClick={() => changeBook(6, 3, bookData.tieLayRate1)}
                                                        style={{ backgroundColor: '#f6b771', width: '20%', fontSize: '16px' }}>
                                                        <span style={{ color: '#000' }}
                                                            fullmarketodds="1.82">{bookData.tieLayRate1}</span>
                                                    </td>
                                                    <td class="tableheader" style={{ backgroundColor: 'white', color: '#374049', width: '30%' }}>
                                                        {pnlBook.tie && (pnlBook.teamA != 0 || pnlBook.teamB != 0 || pnlBook.tie != 0) && !isNaN(Math.abs(pnlBook.tie)) &&
                                                            <span id="before" className={`${pnlBook.tie > 0 ? "win" : "lose"}`} >
                                                                {pnlBook.tie > 0 ? pnlBook.tie : '(' + Math.abs(pnlBook.tie) + ')'}</span>}
                                                        {(Bookclick2 || Bookclick || Bookclick3) && defaultStake != ' ' && defaultStake != '' && !isNaN(Math.abs(pnlBook2.tie)) &&
                                                            <span id="after" className={`${pnlBook2.tie > 0 ? "to-win" : "to-lose"}`} >
                                                                {pnlBook2.tie > 0 ? pnlBook2.tie : '(' + Math.abs(pnlBook2.tie) + ')'}</span>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {(Bookclick3 === true && !(bookData.gameStatus != 2 || bookData.runner3Status == 2)) &&
                                            <div>

                                                <div className="modal fade in" id="myModal" role="dialog" style={{ display: 'block' }}>
                                                    <div className="modal-dialog modalbet" style={{ width: '100%' }}>
                                                        <div className="modal-content">
                                                            <div className="modal-body" style={{ padding: 0 }}>
                                                                <div className="innerloader" style={{ display: 'none' }} />
                                                                <div className="table table-responsive" style={{ margin: 0 }}>
                                                                    <table style={{ width: '100%', fontFamily: 'Arial, Helvetica, sans-serif !important' }} className="market-rate-table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="cls-td" style={{ color: '#000', textAlign: 'center' }} id="macthoddsbetslipmatchname">Toss Winner Market</td>
                                                                                <td className="cls-td" style={{ color: '#000', textAlign: 'center' }} id="modal_type">{yesnoval}</td>
                                                                                <td className="cls-td" style={{ color: '#000', textAlign: 'center' }} id="macthoddsbetslipodds">{fOdds} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={2} style={{ padding: 0 }}><input style={{ color: '#223f6f', backgroundColor: '#e9e9e9', fontSize: '16px', border: 'none', textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 'bold' }} type="text" id="stakeinputmarchodds" className="form-control" placeholder=" Enter Coins" defaultValue={defaultStake} /></td>
                                                                                <td className="close-num-keyboard" id="close-numeric-keyboard" style={{ textAlign: 'center', color: '#fff', backgroundColor: '#1fabb5' }} onClick={() => { closeBookBet(); setdefaultStake(dStake); }}><button style={{ border: '0px', background: '#0000', color: '#fff', paddingTop: '5px' }} type="button" className data-dismiss="modal"><i className="fa fa-times-circle" style={{ fontSize: '28px', textAlign: 'center' }} /></button></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="table table-responsive" style={{ margin: 0 }}>
                                                                    <table style={{}} className="num-pad min-max-stack-table">
                                                                        <tbody>
                                                                            <tr className="bet-values-row">
                                                                                <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('1000')}>1H</td>
                                                                                <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('5000')}>5H</td>
                                                                                <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('10000')}>10H</td>
                                                                                <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('25000')}>25H</td>
                                                                                <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('50000')}>50H</td>
                                                                            </tr>
                                                                            <tr className="bet-values-row">
                                                                                <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('100000')}>1L</td>
                                                                                <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('200000')}>2L</td>
                                                                                <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('500000')}>5L</td>
                                                                                <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('1000000')}>10L</td>
                                                                                <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('2500000')}>25L</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="table table-responsive" style={{ margin: 0 }}>
                                                                    <table style={{ width: '100%', fontSize: '80px' }} className="num-pad numpad-table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }}>1</td>
                                                                                <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }}>2</td>
                                                                                <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }}>3</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }}>4</td>
                                                                                <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }}>5</td>
                                                                                <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }}>6</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }}>7</td>
                                                                                <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }}>8</td>
                                                                                <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }}>9</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }}>c</td>
                                                                                <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }}>0</td>
                                                                                <td className="cls-td text-center" id="clear" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }}><i className="fa fa-times" aria-hidden="true" /></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="table table-responsive" style={{ margin: 0 }}>
                                                                    <table style={{ width: '100%', fontFamily: 'Arial, Helvetica, sans-serif !important' }} className="table table-responsive">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th style={{ background: '#1fabb5', lineHeight: '30px', textAlign: 'center' }} onClick={() => { closeBookBet(); setdefaultStake(dStake); }}><button style={{ border: '0px', background: '#0000', color: '#fff' }} type="button" className data-dismiss="modal">CANCEL</button></th>
                                                                                <th style={{ background: '#c96e6d', lineHeight: '30px', textAlign: 'center' }} onClick={(e) => { e.preventDefault(); bookBet(); closeBookBet(); props.handleRefreshhh(5000, 'Placing bet...', 1); setdefaultStake(dStake); }} ><button style={{ border: '0px', background: '#0000', color: '#fff' }} type="button" id="placeFancyBet" className="btn-round-done"><i className="fa fa-circle-o-notch fa-spin innerloader" style={{ display: 'none', fontSize: '24px', position: 'absolute', lineHeight: '30px' }} />DONE</button></th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 
                                                <div style={{ height: '250px', width: '100%', backgroundColor: '#c96e6d', border: '1px solid #c96e6d' }}>
                                                    <div style={{ height: '70px', width: '100%' }}>
                                                        <div style={{ height: '20px', width: '100%', padding: '5px', paddingRight: '70px', fontSize: '12px', textAlign: 'right' }}>Min Bet: {parseInt(marketData.minStack) * coinFactor}</div>
                                                        <div style={{ height: '50px', width: '100%', backgroundColor: '#c96e6d' }}>

                                                            <div style={{ height: '34px', width: '40%', backgroundColor: 'whitesmoke', float: 'left', borderRadius: '20px', border: '1px solid gray', margin: '3px 5px' }}>
                                                                <div id="inputOdds" class="input-num">
                                                                    <div style={{ height: '33px', width: '25%', float: 'left' }}>

                                                                    </div>
                                                                    <div id="odds" class="typed" style={{ height: '32px', width: '50%', float: 'left', backgroundColor: 'whitesmoke' }}>
                                                                        <b>{mOdds}</b>
                                                                    </div>
                                                                    <div style={{ height: '33px', width: '25%', float: 'left' }}>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ height: '34px', width: '40%', backgroundColor: 'whitesmoke', float: 'right', borderRadius: '20px', border: '1px solid gray', margin: '3px 5px' }}>
                                                                <div id="inputOdds" class="input-num" onChange={(e) => { changeDefaultStake(e); }} type="text" value={defaultStake}>
                                                                    <div style={{ height: '33px', width: '25%', float: 'left', borderRight: '1px solid gray' }}>
                                                                        <a id="oddsDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(bookData2.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }} >
                                                                            <img style={{ height: '25px', width: '20px', paddingTop: '10px' }} src={iconminus} />
                                                                        </a>
                                                                    </div>
                                                                    <div id="odds" class="typed" style={{ height: '32px', width: '50%', float: 'left', backgroundColor: 'whitesmoke' }} autoFocus>
                                                                        <b>{defaultStake}</b>
                                                                    </div>

                                                                    <div style={{ height: '33px', width: '25%', float: 'left', borderLeft: '1px solid gray' }}>
                                                                        <a id="oddsUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(bookData2.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }}>
                                                                            <img style={{ height: '25px', width: '20px', paddingTop: '10px' }} src={iconplus} />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ height: '37px', width: '100%', backgroundColor: 'rgb(116 141 160)', borderRadius: '20px' }}>
                                                        <li class="betvalue_li" onClick={() => setdefaultStake(ssstake1)}>{ssstake1}</li>
                                                        <li class="betvalue_li" onClick={() => setdefaultStake(ssstake2)}>{ssstake2}</li>
                                                        <li class="betvalue_li" onClick={() => setdefaultStake(ssstake3)}>{ssstake3}</li>
                                                        <li class="betvalue_li" onClick={() => setdefaultStake(ssstake4)}>{ssstake4}</li>
                                                        <li class="betvalue_li" onClick={() => setdefaultStake(ssstake3)}>{ssstake5}</li>
                                                        <li class="betvalue_li" onClick={() => setdefaultStake(ssstake4)}>{ssstake6}</li>
                                                        <li class="betvalue_li" onClick={() => setdefaultStake(ssstake5)}>{ssstake7}</li>
                                                        <li class="betvalue_li" onClick={() => setdefaultStake(ssstake6)} style={{ borderRight: 'none' }}>{ssstake8}</li>                                                        </div>
                                                    <div style={{ height: '8px' }}></div>
                                                    <div style={{ height: '80px', width: '100%', border: '1px solid gray', borderRadius: '20px', backgroundColor: 'whitesmoke' }}>
                                                        <div style={{ height: '80px', width: '85%', float: 'left' }}>
                                                            <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray', borderTopLeftRadius: '7px ' }}>1</li>
                                                            <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>2</li>
                                                            <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>3</li>
                                                            <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>4</li>
                                                            <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>5</li>
                                                            <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>6</li>
                                                            <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} style={{ borderRight: '1px solid gray', borderBottomLeftRadius: '7px', borderBottom: '1px solid gray' }}>7</li>
                                                            <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>8</li>
                                                            <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>9</li>
                                                            <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>0</li>
                                                            <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>00</li>
                                                            <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '.') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>.</li>

                                                        </div>
                                                        <div style={{ height: '78px', width: '15%', float: 'left', }} onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }}>
                                                            <div style={{ height: '50px', width: '50px', borderRadius: '50%', border: '1px solid gray', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '15px', paddingTop: '14px' }} >Clear</div>
                                                        </div>
                                                    </div>
                                                    <div style={{ height: '50px', width: '100%' }}>
                                                        <div style={{ height: '50px', width: '50%', float: 'left' }}>
                                                            <div onClick={() => { closeBookBet(); setdefaultStake(dStake); }} class=" betslipcancel_btn"><b>Cancel</b></div>
                                                        </div>
                                                        <div style={{ height: '50px', width: '50%', float: 'left' }}>
                                                            <div class=" betslipPlacebet_btn" onClick={(e) => { e.preventDefault(); bookBet(); closeBookBet(); props.handleRefreshhh(5000, 'Placing bet...', 1); setdefaultStake(dStake); }}><b>Place Bet</b></div>
                                                        </div>
                                                    </div>
                                                </div>
                                             */}
                                            </div>
                                        }
                                    </React.Fragment>}
                            </div>
                        </div>
                    </div>
                </div>
            }


            {eventT == 4 &&
                <div class="container matchList-matchBox-outer matchBox-outer" style={{ padding: 0 }}>
                    <div class="content-top-padding overall_page_streach">
                        <div>
                            <div style={{ height: '35px', fontWeight: 'bold', width: '100%', padding: '7px 10px', textAlign: 'center', fontSize: '15px', color: 'white', backgroundColor: '#c96e6e', display: (fancyData.length > 0 ? 'block' : 'none') }}>
                                Session</div>
                            <div style={{ backgroundColor: 'whitesmoke', display: (fancyData.length > 0 ? 'block' : 'none') }}>
                                <table style={{ height: '40px', width: '100%', border: '5px solid whitesmoke' }}>
                                    <tbody>
                                        <tr >
                                            <th class="tableheader" style={{ backgroundColor: '#374049', color: 'white', width: '36%' }}>Fancy Name</th>
                                            <th class="tableheader" style={{ backgroundColor: 'whitesmoke', color: '#49494A', width: '16%' }}>NO</th>
                                            <th class="tableheader" style={{ backgroundColor: '#49494A', color: 'white', width: '16%' }}>RATE</th>
                                            <th class="tableheader" style={{ backgroundColor: 'whitesmoke', color: '#49494A', width: '16%' }}>YES</th>
                                            <th class="tableheader" style={{ backgroundColor: '#49494A', color: 'white', width: '16%' }}>RATE</th>
                                        </tr>
                                    </tbody>
                                </table>

                                {fancyData.map(function (item, index) {

                                    var pnl = null;
                                    var pnl2 = null;

                                    if (pnlFancy[item.selectionId]) {
                                        pnl = parseFloat(pnlFancy[item.selectionId]).toFixed(2);
                                    }
                                    if (click === index) {

                                        if (field === 1 && defaultStake != ' ' && defaultStake != '') {
                                            pnl2 = -(parseFloat(fsize / 100) * parseFloat(defaultStake)).toFixed(2);
                                        }
                                        else if (field === 2 && defaultStake != ' ' && defaultStake != '') {
                                            pnl2 = -Math.abs(defaultStake);
                                        }
                                    }
                                    return (
                                        <React.Fragment>

                                            <div style={{ height: '20px', width: '100%', fontWeight: 'bold', padding: '1.5px 10px', textAlign: 'left', fontSize: '12px', color: '#374049', backgroundColor: 'whitesmoke' }}>
                                                {item.runnerName}</div>
                                            <table style={{ height: '40px', width: '100%', border: '5px solid whitesmoke' }}>
                                                <tbody>
                                                    <tr>
                                                        <td class="tableheader" style={{ backgroundColor: 'white', color: 'white', width: '36%' }}>
                                                            {pnl && <span id="before" className={`${pnl > 0 ? "win" : "lose"}`}>{pnl > 0 ? pnl : '(' + Math.abs(pnl) + ')'}</span>}
                                                        </td>

                                                        <td class="tableheader" onClick={() => { if (!props.isLoggedIn) { props.checkShowLogin(true); return; } OpenfancyBet(index); setfOdds(item.layPrice); setyesnoval("NO"); setfsize(item.laySize); }} style={item.gameStatus != 2 ? { pointerEvents: 'none', backgroundColor: '#f6b771', width: '16%' } : { backgroundColor: '#f6b771', width: '16%' }}>
                                                            <span class="lay-1" className={`lay-1 ${(selectFancy === 1 && click === index && field === 1) ? "select" : "null"}`}  >{item.layPrice ? item.layPrice : '--'}</span>

                                                        </td>
                                                        <td class="tableheader" style={item.gameStatus != 2 ? { pointerEvents: 'none', backgroundColor: '#e3f3fe', color: '#374049', width: '16%' } : { backgroundColor: '#e3f3fe', color: '#374049', width: '16%', fontSize: '13px' }} > {item.laySize ? item.laySize : '--'}</td>
                                                        <td class="tableheader" onClick={() => { if (!props.isLoggedIn) { props.checkShowLogin(true); return; } OpenfancyBetLay(index); setfOdds(item.backPrice); setyesnoval("YES"); setfsize(item.backSize); }} style={item.gameStatus != 2 ? { pointerEvents: 'none', backgroundColor: '#a7d8fd', width: '16%', fontSize: '13px' } : { backgroundColor: '#a7d8fd', width: '16%' }}>
                                                            <span class="back-1" className={`back-1 ${(selectFancyLay === 1 && click === index && field === 2) ? "select" : "null"}`}  >{item.backPrice ? item.backPrice : '--'}</span></td>
                                                        <td class="tableheader" style={{ backgroundColor: '#e3f3fe', color: '#374049', width: '16%' }}>{item.backSize ? item.backSize : '--'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {/* betslip */}
                                            {click === index &&
                                                <div>

                                                    <div className="modal fade in" id="myModal" role="dialog" style={{ display: 'block' }}>
                                                        <div className="modal-dialog modalbet" style={{ width: '100%' }}>
                                                            <div className="modal-content">
                                                                <div className="modal-body" style={{ padding: 0 }}>
                                                                    <div className="innerloader" style={{ display: 'none' }} />
                                                                    <div className="table table-responsive" style={{ margin: 0 }}>
                                                                        <table style={{ width: '100%', fontFamily: 'sans-serif' }} className="market-rate-table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="cls-td" style={{ color: '#000', textAlign: 'center' }} id="macthoddsbetslipmatchname">{item.runnerName}</td>
                                                                                    <td className="cls-td" style={{ color: '#000', textAlign: 'center' }} id="modal_type">{yesnoval}</td>
                                                                                    <td className="cls-td" style={{ color: '#000', textAlign: 'center' }} id="macthoddsbetslipodds">{fOdds} </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={2} style={{ padding: 0 }}><input style={{ color: '#223f6f', backgroundColor: '#e9e9e9', fontSize: '16px', border: 'none', textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 'bold' }} type="text" id="stakeinputmarchodds" className="form-control" placeholder=" Enter Coins" defaultValue={defaultStake} /></td>
                                                                                    <td className="close-num-keyboard" id="close-numeric-keyboard" style={{ textAlign: 'center', color: '#fff', backgroundColor: '#1fabb5' }} onClick={() => { closeFancyBet(); setdefaultStake(dStake); }}><button style={{ border: '0px', background: '#0000', color: '#fff', paddingTop: '5px' }} type="button" className data-dismiss="modal"><i className="fa fa-times-circle" style={{ fontSize: '28px', textAlign: 'center' }} /></button></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="table table-responsive" style={{ margin: 0 }}>
                                                                        <table style={{}} className="num-pad min-max-stack-table">
                                                                            <tbody>
                                                                                <tr className="bet-values-row">
                                                                                    <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('1000')}>1H</td>
                                                                                    <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('5000')}>5H</td>
                                                                                    <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('10000')}>10H</td>
                                                                                    <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('25000')}>25H</td>
                                                                                    <td className="cls-td text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('50000')}>50H</td>
                                                                                </tr>
                                                                                <tr className="bet-values-row">
                                                                                    <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('100000')}>1L</td>
                                                                                    <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('200000')}>2L</td>
                                                                                    <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('500000')}>5L</td>
                                                                                    <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('1000000')}>10L</td>
                                                                                    <td className="text-center" style={{ textAlign: 'center', lineHeight: '40px', fontWeight: 'bold' }} onClick={() => setdefaultStake('2500000')}>25L</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="table table-responsive" style={{ margin: 0 }}>
                                                                        <table style={{ width: '100%', fontSize: '80px' }} className="num-pad numpad-table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }}>1</td>
                                                                                    <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }}>2</td>
                                                                                    <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }}>3</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }}>4</td>
                                                                                    <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }}>5</td>
                                                                                    <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }}>6</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }}>7</td>
                                                                                    <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }}>8</td>
                                                                                    <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }}>9</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }}>c</td>
                                                                                    <td className="cls-td text-center fancy-modal-number" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }} onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }}>0</td>
                                                                                    <td className="cls-td text-center" id="clear" style={{ textAlign: 'center', lineHeight: '35px', fontSize: '15px', color: '#000' }}><i className="fa fa-times" aria-hidden="true" /></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="table table-responsive" style={{ margin: 0 }}>
                                                                        <table style={{ width: '100%', fontFamily: 'Arial, Helvetica, sans-serif !important' }} className="table table-responsive">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th style={{ background: '#1fabb5', lineHeight: '30px', textAlign: 'center' }} onClick={() => { closeFancyBet(); setdefaultStake(dStake); }}><button style={{ border: '0px', background: '#0000', color: '#fff' }} type="button" className data-dismiss="modal">CANCEL</button></th>
                                                                                    <th style={{ background: '#c96e6d', lineHeight: '30px', textAlign: 'center' }} onClick={(e) => { e.preventDefault(); fancyBet(item.selectionId, field, item.runnerName); closeFancyBet(); props.handleRefreshhh(3000, 'Placing bet...', 1); setdefaultStake(dStake); }} ><button style={{ border: '0px', background: '#0000', color: '#fff' }} type="button" id="placeFancyBet" className="btn-round-done"><i className="fa fa-circle-o-notch fa-spin innerloader" style={{ display: 'none', fontSize: '24px', position: 'absolute', lineHeight: '30px' }} />DONE</button></th>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*                                                   
                                                    <div style={{ height: '250px', width: '100%', backgroundColor: '#c96e6d', border: '1px solid #c96e6d' }}>
                                                        <div style={{ height: '70px', width: '100%' }}>
                                                            <div style={{ height: '20px', width: '100%', padding: '5px', paddingRight: '70px', fontSize: '12px', textAlign: 'right' }}>Min Bet: {parseInt(item.minStack) * coinFactor} </div>
                                                            <div style={{ height: '50px', width: '100%', backgroundColor: '#c96e6d' }}>
                                                                <div style={{ height: '35px', width: '40%', backgroundColor: 'whitesmoke', float: 'left', borderRadius: '20px', border: '1px solid gray', margin: '3px 5px' }}>
                                                                    <div id="inputOdds" class="input-num">

                                                                        <div id="odds" class="typed" style={{ height: '33px', width: '100%', backgroundColor: 'whitesmoke', borderRadius: '20px' }}>
                                                                            <b>{fOdds} / {fsize}</b>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div style={{ height: '34px', width: '40%', backgroundColor: 'whitesmoke', float: 'right', borderRadius: '20px', border: '1px solid gray', margin: '3px 5px' }}>
                                                                    <div id="inputOdds" class="input-num" onChange={(e) => { changeDefaultStake(e); }} value={defaultStake}>
                                                                        <div style={{ height: '33px', width: '25%', float: 'left', borderRight: '1px solid gray' }}>
                                                                            <a id="oddsDown" class="icon-minus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(item.minStack) * coinFactor).toString()) } else { if (parseInt(defaultStake) > 0) setdefaultStake((parseInt(defaultStake) - 1).toString()) } }}>
                                                                                <img style={{ height: '25px', width: '20px', paddingTop: '10px' }} src={iconminus} />
                                                                            </a>
                                                                        </div>
                                                                        <div id="odds" class="typed" style={{ height: '32px', width: '50%', float: 'left', backgroundColor: 'whitesmoke' }}>
                                                                            <b>{defaultStake}</b>
                                                                        </div>
                                                                        <div style={{ height: '33px', width: '25%', float: 'left', borderLeft: '1px solid gray' }}>
                                                                            <a id="oddsUp" class="icon-plus" onClick={() => { if (defaultStake === '' || defaultStake === ' ') { setdefaultStake((parseInt(item.minStack) * coinFactor).toString()) } else { setdefaultStake((parseInt(defaultStake) + 1).toString()) } }}>
                                                                                <img style={{ height: '25px', width: '20px', paddingTop: '10px' }} src={iconplus} />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ height: '37px', width: '100%', backgroundColor: 'rgb(116 141 160)', borderRadius: '20px' }}>
                                                            <li class="betvalue_li" onClick={() => setdefaultStake(ssstake1)}>{ssstake1}</li>
                                                            <li class="betvalue_li" onClick={() => setdefaultStake(ssstake2)}>{ssstake2}</li>
                                                            <li class="betvalue_li" onClick={() => setdefaultStake(ssstake3)}>{ssstake3}</li>
                                                            <li class="betvalue_li" onClick={() => setdefaultStake(ssstake4)}>{ssstake4}</li>
                                                            <li class="betvalue_li" onClick={() => setdefaultStake(ssstake3)}>{ssstake5}</li>
                                                            <li class="betvalue_li" onClick={() => setdefaultStake(ssstake4)}>{ssstake6}</li>
                                                            <li class="betvalue_li" onClick={() => setdefaultStake(ssstake5)}>{ssstake7}</li>
                                                            <li class="betvalue_li" onClick={() => setdefaultStake(ssstake6)} style={{ borderRight: 'none' }}>{ssstake8}</li>                                                            </div>
                                                        <div style={{ height: '8px' }}></div>
                                                        <div style={{ height: '80px', width: '100%', border: '1px solid gray', borderRadius: '20px', backgroundColor: 'whitesmoke' }}>

                                                            <div style={{ height: '80px', width: '85%', float: 'left' }}>
                                                                <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '1') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray', borderTopLeftRadius: '7px ' }}>1</li>
                                                                <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '2') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>2</li>
                                                                <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '3') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>3</li>
                                                                <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '4') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>4</li>
                                                                <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '5') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>5</li>
                                                                <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '6') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>6</li>
                                                                <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '7') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray', borderBottomLeftRadius: '7px' }}>7</li>
                                                                <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '8') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>8</li>
                                                                <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '9') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>9</li>
                                                                <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '0') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>0</li>
                                                                <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '00') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>00</li>
                                                                <li class="numpad" onClick={() => { if (defaultStake.length < 8) setdefaultStake(defaultStake + '.') }} style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray' }}>.</li>

                                                            </div>
                                                            <div style={{ height: '78px', width: '15%', float: 'left', }} onClick={() => { setdefaultStake(defaultStake.toString().substring(0, defaultStake.toString().length - 1)) }}>
                                                                <div style={{ height: '50px', width: '50px', borderRadius: '50%', border: '1px solid gray', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '15px', paddingTop: '14px' }} >Clear</div>
                                                            </div>
                                                        </div>
                                                        <div style={{ height: '50px', width: '100%' }}>
                                                            <div style={{ height: '50px', width: '50%', float: 'left' }}>
                                                                <div class=" betslipcancel_btn" onClick={() => { closeFancyBet(); setdefaultStake(dStake); }}><b>Cancel</b></div>
                                                            </div>
                                                            <div style={{ height: '50px', width: '50%', float: 'left' }}>
                                                                <div class=" betslipPlacebet_btn" onClick={(e) => {
                                                                    e.preventDefault(); fancyBet(item.selectionId, field, item.runnerName);
                                                                    closeFancyBet(); props.handleRefreshhh(3000, 'Placing bet...', 1); setdefaultStake(dStake);
                                                                }}><b>Place Bet</b></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 */}

                                                </div>}


                                        </React.Fragment>)
                                })}
                            </div>

                        </div>
                    </div>
                </div>}
            <Mybets userid={props.userid} utoken={props.utoken} eventId={eventid} updateBet={updateBet} marketid={marketid} />
            {/* <OpenBets utoken={props.utoken} userid={props.userid} /> */}
            {Bookclick2 === true && <div style={{ marginBottom: '370px' }}></div>}
            {Bookclick === true && <div style={{ marginBottom: '370px' }}></div>}
            {Bookclick3 === true && <div style={{ marginBottom: '370px' }}></div>}
            {click >= 0 && <div style={{ marginBottom: '370px' }}></div>}
        </React.Fragment>
    )
}

