import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
// import reportWebVitals from './reportWebVitals';
// import {BrowserRouter} from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Router} from "react-router-dom";
import {createBrowserHistory} from 'history';


const history = createBrowserHistory();
ReactDOM.render(
  <React.StrictMode>
      <Router history={history}>
         <App />
     
  </Router>
  </React.StrictMode>, document.getElementById("root"));
  

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorker.unregister();
