import React, { useState,useEffect } from 'react'
import axios from 'axios';
import {toast} from 'react-toastify'

export default function SettingStack(props) {
    const [buttonStake, setbuttonStake] =useState(true);
    const [ssstake1, setSsstake1] = useState(0);
    const [ssstake2, setSsstake2] = useState(0);
    const [ssstake3, setSsstake3] = useState(0);
    const [ssstake4, setSsstake4] = useState(0);
    const [ssstake5, setSsstake5] = useState(0);
    const [ssstake6, setSsstake6] = useState(0);
    const [ssstake7, setSsstake7] = useState(0);
    const [ssstake8, setSsstake8] = useState(0);
    const [defaultStake, setdefaultStake] = useState('');
    const [Switch, setSwitch] = useState(true);
    const [Switch2, setSwitch2] = useState(true);

    const handleSwitch =()=>{
        setSwitch(!Switch)
      }
    
      const handleSwitch2 =()=>{
        setSwitch2(!Switch2)
      }
      useEffect(() => {
         
          axios.post('https://waveapi.in/api/client/clientssgetclickstake',{
         
          id:props.userid,
          token:props.utoken,
        
      })
      .then(result => {
         if(result.status === 210){
    
              setdefaultStake(result.data[0].defaultStake);
              setSsstake1(result.data[0].SSstake1);
              setSsstake2(result.data[0].SSstake2);
              setSsstake3(result.data[0].SSstake3);
              setSsstake4(result.data[0].SSstake4);
              setSsstake5(result.data[0].SSstake5);
              setSsstake6(result.data[0].SSstake6);
              setSsstake7(result.data[0].SSstake7);
              setSsstake8(result.data[0].SSstake8);
             
            }
        
       }
       
     ).catch(e => {
       //setIsError(true);
     });
          },[])
    
      const handleEditClick = () => {
         // seteditStake(true);
          setbuttonStake(false);
        };
    
      const hadleSaveClick = (e) => {
            
          
          e.preventDefault();
        
         setbuttonStake(true);  
      };
        
      const handleSaveClick = (e) => {
            
          
          e.preventDefault();
         
         setbuttonStake(true);
       
         console.log('userid',props.userid);
         console.log('token',props.utoken);
         axios.post('https://waveapi.in/api/client/clientsssaveclickstake',{
            id:props.userid,
            token:props.utoken,
            defaultStake:defaultStake,
            ssstake1:ssstake1,
            ssstake2:ssstake2,
            ssstake3:ssstake3,
            ssstake4:ssstake4,
            ssstake5:ssstake5,
            ssstake6:ssstake6,
            ssstake7:ssstake7,
            ssstake8:ssstake8,
        
          })
          .then(result => {
            
             if(result.status === 210){
             toast.warn('Bet stake settings saved succefully', {position:toast.POSITION.TOP_CENTER});
             
              console.log('Updated successful')
                 
             }
            
             if(result.status === 220){
               console.log('saveclick unsucessful');
               
               
             }
           }
           
         ).catch(e => {
           //setIsError(true);
         });
        };
        const handleChange1 = (event) => {
          setSsstake1(event.target.value);
        };
        
        const handleChange2 = (event) => {
          setSsstake2(event.target.value);
        };
        const handleChange3 = (event) => {
          setSsstake3(event.target.value);
        };
        const handleChange4 = (event) => {
          setSsstake4(event.target.value);
        };
        const handleChange5 = (event) => {
          setSsstake5(event.target.value);
        };
        
        const handleChange6 = (event) => {
          setSsstake6(event.target.value);
        };
        const handleChange7 = (event) => {
          setSsstake7(event.target.value);
        };
        const handleChange8 = (event) => {
          setSsstake8(event.target.value);
        };
        const changeDefaultStake = (event) => {
            console.log('test');
          setdefaultStake(event.target.value);
        };
         

       

    return (
        
        <React.Fragment>
        <div class="col-sm-6 overall_page_streach" style={{float:'none',margin:'0 auto'}}>
        <div id="coinList" class="side-content">
    <h3>Stake</h3>
    
    <dl className="setting-block">
				<dt>Default stake<input type="number" id="userCoin" onChange={(e)=>{changeDefaultStake(e);}} type="text"  value={defaultStake} pattern="[0-9]*" data-role="none"/></dt>
			</dl>
			
      {buttonStake === true && 
      
			<dl id="stakeSet" className="setting-block stake-setting" style={{flex:'0 1 9.33333vw'}}>
				<dt>Quick Stakes</dt>
      <dd class="stackIdefault"><a id="coin_1" className=" ui-link select"   >{ssstake1}</a></dd>
				<dd class="stackIdefault"><a id="coin_2" className=" ui-link select"   >{ssstake2}</a></dd>
				<dd class="stackIdefault"><a id="coin_3" className=" ui-link select"   >{ssstake3}</a></dd>
				<dd class="stackIdefault"><a id="coin_4" className=" ui-link select"   >{ssstake4}</a></dd>
				<dd class="stackIdefault"><a id="coin_5" className=" ui-link select"   >{ssstake5}</a></dd>
				<dd class="stackIdefault"> <a id="coin_6" className=" ui-link select"   >{ssstake6}</a></dd>
				<dd class="stackIdefault"><a id="coin_7" className=" ui-link select"   >{ssstake7}</a></dd>
				<dd class="stackIdefault"><a id="coin_8" className=" ui-link select"   >{ssstake8}</a></dd>
				<dd class="stackIdefault" className="col-stake_edit"><a id="edit" className="edit ui-link" onClick={(e)=>{handleEditClick(e);}}>Edit Stakes</a></dd>
			</dl>}

      {buttonStake === false && 
			<dl id="editCustomizeStakeList" className="setting-block stake-setting" >
				<dt>Quick Stakes</dt>
				<dd ><div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input id="stakeEdit_1" onChange={(e)=>{handleChange1(e);}} type="text" value={ssstake1} pattern="[0-9]*"/></div></dd>
				<dd ><div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input id="stakeEdit_2" onChange={(e)=>{handleChange2(e);}} type="text" value={ssstake2} pattern="[0-9]*"/></div></dd>
				<dd ><div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input id="stakeEdit_3" onChange={(e)=>{handleChange3(e);}} type="text" value={ssstake3} pattern="[0-9]*"/></div></dd>
				<dd ><div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input id="stakeEdit_4" onChange={(e)=>{handleChange4(e);}} type="text" value={ssstake4} pattern="[0-9]*"/></div></dd>
				<dd ><div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input id="stakeEdit_5" onChange={(e)=>{handleChange5(e);}} type="text" value={ssstake5} pattern="[0-9]*"/></div></dd>
				<dd ><div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input id="stakeEdit_6" onChange={(e)=>{handleChange6(e);}} type="text" value={ssstake6} pattern="[0-9]*"/></div></dd>
				<dd ><div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input id="stakeEdit_7" onChange={(e)=>{handleChange7(e);}} type="text" value={ssstake7} pattern="[0-9]*"/></div></dd>
				<dd ><div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset"><input id="stakeEdit_8" onChange={(e)=>{handleChange8(e);}} type="text" value={ssstake8} pattern="[0-9]*"/></div></dd>
				{/* <dd className="col-stake_edit"><a id="ok" onClick={(e)=>{hadleSaveClick(e);}} className="ui-link"   >OK</a></dd> */}
			</dl>
}


   
    {/* <dl id="stakeSet" class="setting-block stake-setting"><dt>Quick Stakes</dt>
        <dd class="stackIdefault"><a id="coin_1" class=" ui-link select">10</a></dd>
        <dd class="stackIdefault"><a id="coin_2" class=" ui-link select">20</a></dd>
        <dd class="stackIdefault"><a id="coin_3" class=" ui-link select">50</a></dd>
        <dd class="stackIdefault"><a id="coin_4" class=" ui-link select">80</a></dd>
        <dd class="stackIdefault"><a id="coin_5" class=" ui-link select">100</a></dd>
        <dd class="stackIdefault"><a id="coin_6" class=" ui-link select">20</a></dd>
        <dd class="stackIdefault"><a id="coin_7" class=" ui-link select">30</a></dd>
        <dd class="stackIdefault"><a id="coin_8" class=" ui-link select">50</a></dd>
        <dd class="stackIdefault" class="col-stake_edit"><a id="edit" class="btn-edit ui-link">Edit Stakes</a></dd>
    </dl> */}

    <ul class="btn-list">
        {/* <li><a id="settingCancel" class="buttonCancel"  >Cancel</a></li> */}
        <li><a id="settingSave" class="buttonSave" onClick={(e)=>{handleSaveClick(e);}} style={{color:'#fff'}}>Update</a></li>
    </ul>
</div>
        </div>
        </React.Fragment>
    )
}
