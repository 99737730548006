import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify'
import Match_bet_status from './Match_bet_status'

export default function Ledger(props) {

    const [lederdata, setlederdata] = useState([])

    useEffect(() => {

        axios.post('https://waveapi.in/api/client/cli_led_data', {
            id: props.userid,

        })
            .then(result => {
                setlederdata(result.data)
            }
            ).catch(e => {
            });
    }, [])




    return (
        <div >
            <div className="container ledger-table-container-3">
                <div className="row content-top-padding" style={{ paddingBottom: '15px' }}>
                    <div style={{ overflowY: 'scroll', width: '150%' }} >
                        <table className="table table-bordered header-top-spacing"  >
                            <thead>
                                <tr>
                                    <th className="ldg-tbl-th match-box-color" style={{ width: '25%' }}>DATE</th>
                                    <th className="ldg-tbl-th match-box-color" style={{ width: '30%' }}>ENTRY</th>
                                    <th className="ldg-tbl-th match-box-color" style={{ width: '15%' }}>DEBIT</th>
                                    <th className="ldg-tbl-th match-box-color" style={{ width: '15%' }}>CREDIT</th>
                                    <th className="ldg-tbl-th match-box-color" style={{ width: '15%' }}>BALANCE</th>
                                </tr>
                            </thead>
                            {lederdata.map(function (item, index) {
                                var deb, cr
                                if(item.netPL>0){
                                    deb=0;cr=item.netPL
                                }
                                else{
                                    cr=0;deb=item.netPL
                                }
                                    
                               
                                return (
                                    <thead>
                                        <tr>
                                            <th className="ldg-tbl-th match-box-color" style={{ width: '20%', backgroundColor:'white', color:'black' }}>{item.timeCurr}</th>
                                           <a href={`/Match_bet_status/${item.eventId}/${item.netPL}`}> <th className="ldg-tbl-th match-box-color" style={{ width: '35%', backgroundColor:'white', color:'#983838' }}>{item.eventName}</th></a>
                                            <th className="ldg-tbl-th match-box-color" style={{ width: '15%', backgroundColor:'white', color:'black' }}>{deb}</th>
                                            <th className="ldg-tbl-th match-box-color" style={{ width: '15%', backgroundColor:'white', color:'black' }}>{cr}</th>
                                            <th className="ldg-tbl-th match-box-color" style={{ width: '15%', backgroundColor:'white', color:'black' }}>{item.ledger_balance}</th>
                                        </tr>
                                    </thead>
                                )
                            })}
                            <tbody>
                            </tbody>
                        </table>

                    </div>

                </div>
            </div>




        </div>

    )
}




{/* <div style={{ height: 'auto', width: '150%', overflow: 'auto' }}>
            <div style={{ backgroundColor: 'whitesmoke' }}>
                <table style={{ height: '40px', width: '120%', border: '5px solid whitesmoke' }}>
                    <tr >
                        <th class="ledgerheader" style={{ backgroundColor: '#49494A', color: 'white', width: '15%' }}>DATE</th>
                        <th class="ledgerheader" style={{ backgroundColor: '#49494A', color: 'white', width: '40%' }}>ENTRY</th>
                        <th class="ledgerheader" style={{ backgroundColor: '#49494A', color: 'white', width: '15%' }}>DEBIT</th>
                        <th class="ledgerheader" style={{ backgroundColor: '#49494A', color: 'white', width: '15%' }}>CREDIT</th>
                        <th class="ledgerheader" style={{ backgroundColor: '#49494A', color: 'white', width: '15%' }}>BALANCE</th>
                    </tr>
                </table>
                <table style={{ height: '50px', width: '120%', border: '5px solid whitesmoke' }}>
                    <tr >
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>25 Aug 2021</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '40%' }}>IND VS AUS</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>23000.00</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>34000.00</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>123000.00</td>
                    </tr>
                    <tr >
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>25 Aug 2021</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '40%' }}>IND VS AUS</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>23000.00</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>34000.00</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>123000.00</td>
                    </tr>
                    <tr >
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>25 Aug 2021</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '40%' }}>IND VS AUS</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>23000.00</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>34000.00</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>123000.00</td>
                    </tr>
                    <tr >
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>25 Aug 2021</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '40%' }}>IND VS AUS</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>23000.00</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>34000.00</td>
                        <td class="ledgerdatalist" style={{ backgroundColor: '#f9c9d4', width: '15%' }}>123000.00</td>
                    </tr>
                    
                </table>
            </div>
        </div> */}