import React from 'react'


export default function Home() {
    return (
        <React.Fragment>
              <div className="viewing-page-1 disabled-onepage-scroll">
            <div className="wrapper">
      <div className="main onepage-wrapper" style={{position: "relative"}}> 
        {/* <!-- page 1--> */}
        <section id="page1" className="section active" data-index="1" style={{position: 'absolute', top: '0%', left: '0px',height:'764px'}}>
          <div className="overlay"></div>
          <div className="content" style={{marginTop: '0px'}}>
            <div className="container clearfix">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 col-sm-12">
                  <h1 className="main-page-heading heading">NICE7777</h1>
                  <a href="/users/sign_in">
                  <button className="btnl btnl-1 btnl-1c">Login</button></a>
                </div>
              </div>
            </div>
          </div>
          <div className="icon faa-float"><i className="fa fa-angle-double-down"></i></div>
        </section>
       </div>
    </div>
    </div>
        </React.Fragment>
    )
}
